<script>
import { mapMutations } from 'vuex';
import * as saleMutations from '@/store/sale/mutations';
import * as purchaseMutations from '@/store/purchase/mutations';
import * as remortgageMutations from '@/store/remortgage/mutations';
import findSigningToken from '@/api/yoti/findSigningToken';
import ConfirmationModal from './common/ConfirmationModal.vue';
import IframeModal from './common/IframeModal.vue';
import EventButton from './common/EventButton.vue';

const documentTypes = {
  CLIENT_QUESTIONNAIRE: 'questionnaire',
  STANDALONE_TOE_CLIENT_QUESTIONNAIRE: 'questionnaire',
  CLIENT_CARE_PACK: 'clientCarePack',
  TA6: 'ta6',
  TA7: 'ta7',
  TA10: 'ta10',
};

export default {
  name: 'Yoti',
  components: { EventButton, IframeModal, ConfirmationModal },
  data() {
    return {
      contentUrl: '',
      loading: false,
      mode: 'page', // could be also view as 'modal'
      showModal: false,
      showConfirmationModal: false,
      requireConfirmation: true,
    };
  },
  props: [
    'caseReference',
    'client',
    'clientId',
    'documents',
    'documentName',
    'documentType',
    'entityId',
    'entityType',
    'events',
    'eventKey',
    'eventName',
    'existingTrackingEvents',
    'locked',
    'parentLoading',
    'taskStatus',
    'supplierInformationName',
  ],
  methods: {
    ...mapMutations([
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_EVENT_TO_HANDLE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_EVENT_TO_HANDLE}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EVENT_TO_HANDLE}`,
    ]),
    dismissModal() {
      this.loading = true;
      this.showModal = false;
      this.$emit('reload', this.eventKey);
    },
    showConfirmation() {
      if (this.requireConfirmation) {
        this.showConfirmationModal = true;
        this.showModal = false;
      } else {
        this.showContent();
      }
    },
    confirmed(value) {
      if (value) {
        this.showContent();
      }
      this.showConfirmationModal = false;
    },
    async showContent() {
      if (this.mode !== 'modal') {
        // Set event to handle as we need to set loader on selected button and await for status
        if (this.entityType === 'sale') {
          this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_EVENT_TO_HANDLE}`](
            `requested-key:${this.eventKey}`,
          );
        } else if (this.entityType === 'purchase') {
          this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_EVENT_TO_HANDLE}`](
            `requested-key:${this.eventKey}`,
          );
        } else if (this.entityType === 'remortgage') {
          this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EVENT_TO_HANDLE}`](
            `requested-key:${this.eventKey}`,
          );
        }
        const token = await this.findToken();
        this.contentUrl = `${process.env.VUE_APP_EMBEDDED_YOTI_SIGN_ENDPOINT}/${token}`;
        this.navigate('EmbeddedYotiSign', { eventId: this.eventId, eventType: this.eventType, token });
      } else {
        this.showModal = true;
      }
    },
    async findToken() {
      if (this.documents) {
        const documents = this.documents.filter((d) => {
          if (Array.isArray(this.documentType)) {
            return this.documentType.includes(d.documentType) && d.yotiDocuSign;
          }
          return d.documentType === this.documentType && d.yotiDocuSign;
        });
        if (documents.length === 1) {
          const documentType = documentTypes[documents[0].documentType];
          return (await findSigningToken(documents[0].sortreferId, documentType, this.client.person_id))?.token;
        }
      }
      return undefined;
    },
    fullName() {
      return [this.client?.first_name, this.client?.middle_name, this.client?.last_name].filter((v) => v).join(' ');
    },
    isComplete() {
      const isComplete = !!this.existingTrackingEvents.find((t) => {
        const clientEmail = this.client.email.toLowerCase();
        return (
          t.event_slug === this.events.completed ||
          (t.event_slug === this.events.individualCompleted && t?.metadata?.email.toLowerCase() === clientEmail)
        );
      });
      if (isComplete) {
        this.loading = false;
      }

      return isComplete;
    },
    isStarted() {
      return !!this.findToken();
    },
    isParentComplete() {
      if (this.taskStatus == 'completed') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.parentLoading) {
      this.loading = true;
    }
  },
  watch: {
    parentLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<template>
  <div v-if="isParentComplete()" class="row d-flex align-items-center border-top p-2 bg-light">
    <div class="col-sm-12 col-md-6">
      <div class="d-flex justify-content-center justify-content-md-start">
        <h4 class="text-default font-weight-semibold letter-icon-title text-center">
          <span class="pl-3"><i class="icon-pencil3 mr-2" /></span>
          {{
            supplierInformationName === 'Sort Legal' && documentName === 'Client Care Pack'
              ? 'Sort Legal Client Care Pack Signed By '
              : eventName
          }}
          {{ this.fullName() }}
        </h4>
      </div>
    </div>

    <div class="col-sm-12 col-md-2">
      <div class="d-flex justify-content-center justify-content-md-start">
        <h4 class="status text-uppercase">
          <span :class="`badge-${isComplete() ? 'success' : 'danger'} badge badge-pill border-3 mr-1`">
            {{ isComplete() ? 'Complete' : 'Incomplete' }}
          </span>
        </h4>
      </div>
    </div>

    <div class="col-sm-12 col-md-2">
      <div v-if="!isComplete()" class="d-flex justify-content-center justify-content-md-start text-nowrap">
        <h4
          class="cursor-pointer text-info d-inline ml-md-3"
          @click="
            $emit('show-more-info-modal', { eventName: events.individualCompleted, displayAlternativeText: true })
          "
        >
          <i class="icon-info22 mr-2 icon"></i>
          <span>More info</span>
        </h4>
      </div>
    </div>

    <div class="col-sm-12 col-md-2">
      <event-button
        v-if="isStarted()"
        :completed="isComplete()"
        :disabled="locked"
        :loading="loading"
        v-on:clicked="() => showConfirmation()"
      >
        <span v-if="this.loading">Checking Sign Status...</span>
        <span v-else-if="isComplete()">Signed</span>
        <span v-else>Sign</span>
      </event-button>
      <confirmation-modal
        v-if="requireConfirmation && showConfirmationModal"
        :visible="showConfirmationModal"
        :client="client"
        :document-name="documentName"
        v-on:confirmed="(e) => confirmed(e)"
      ></confirmation-modal>
      <iframe-modal
        v-if="!isComplete() && isStarted()"
        :src="contentUrl"
        :visible="showModal"
        v-on:dismiss="() => dismissModal()"
      ></iframe-modal>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  h3,
  h4,
  span {
    margin: 0;
    padding: 5px;
  }
}
</style>
