import { TA6_COMPLETED, TA6_STARTED } from '@/values/trackingEvents';

export default {
  name: 'Property Information Form',
  path: 'Ta6Form',
  icon: 'TA6',
  event_slug: 'property_information_form',
  trackingEvents: {
    startedEvent: TA6_STARTED,
    completedEvent: TA6_COMPLETED,
  },
  visible: true,
  active: false,
  status: false,
  whatsNextInfo: false,
};
