<script>
import Modal from '@/components/Modal';

export default {
  components: { Modal },
  name: 'ConfirmationModal',
  props: ['visible', 'client', 'documentName'],
  methods: {
    confirmation(value) {
      this.$emit('confirmed', value);
    },
  },
};
</script>
<template>
  <div class="col-6 col-md-2">
    <modal
      v-if="visible"
      :show-buttons="true"
      closeButtonText="Cancel Signing Request"
      :fullModalWidth="false"
      proceedButtonText="Continue And Sign"
      @proceed="this.confirmation"
    >
      <div class="text-center">
        <i class="icon-question7 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
        <h3>Are you {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}?</h3>
        <p>
          This is a <strong>{{ documentName }}</strong> signing request for the following person:
          <strong>{{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}.</strong>
        </p>

        <p>
          If you are not <strong>{{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}</strong
          >, click cancel and select the correct signing request with your name.
        </p>

        <p>
          By clicking 'Continue And Sign' you are confirming that you are
          <strong>{{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}.</strong>
        </p>
      </div>
    </modal>
  </div>
</template>
