var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loader',{staticClass:"pb-2"},[(_vm.showDelayInfo)?_c('span',[_vm._v("Please wait, creating list of tasks...")]):_vm._e()]):_c('section',[_c('div',[_vm._l((_vm.trackingEventsToDisplay),function(trackingEventToDisplay,key){return _c('div',{key:key,staticClass:"row d-flex align-items-center border-top p-2 bg-light"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"d-flex justify-content-center justify-content-md-start"},[_c('h4',{staticClass:"text-default font-weight-semibold letter-icon-title"},[_c('span',{staticClass:"pl-3"},[_vm._v("-")]),_vm._v(" "+_vm._s(_vm.renderHumanName(trackingEventToDisplay))+" ")])])]),_c('div',{staticClass:"col-sm-12 col-md-1"},[_c('div',{staticClass:"d-flex justify-content-center justify-content-md-start"},[_c('h4',{staticClass:"status"},[_c('span',{class:((trackingEventToDisplay.completed ? 'badge-success' : 'badge-danger') + " badge badge-pill border-3 mr-1 mb-1 text-uppercase")},[_vm._v(_vm._s(trackingEventToDisplay.completed ? 'Complete' : 'Incomplete'))])])])]),_c('div',{staticClass:"col-sm-12 col-md-2"},[(_vm.moreInfoLinksVisible && !trackingEventToDisplay.completed)?_c('h4',{staticClass:"d-flex justify-content-center justify-content-md-start"},[_c('span',{staticClass:"cursor-pointer text-info d-inline text-nowrap ml-md-3",on:{"click":function($event){return _vm.showMoreInfoModal(trackingEventToDisplay)}}},[_c('i',{staticClass:"icon-info22 mr-2 icon"}),_c('span',[_vm._v("More info")])])]):_vm._e()])])}),_vm._l((this.trackedEvents),function(event,k){return _c(event.component,_vm._b({key:k,tag:"component",on:{"reload":function (e) { return _vm.setUpdateDetector(e); },"show-more-info-modal":function (e) { return _vm.showMoreInfoModal(e); }}},'component',Object.assign({}, event,
        {client: _vm.clientsById[event.clientId],
        documents: _vm.documents,
        entityId: _vm.entityId,
        entityType: _vm.entityType,
        existingTrackingEvents: _vm.existingTrackingEvents,
        eventKey: k,
        locked: _vm.locked,
        taskStatus: _vm.taskStatus}),false))}),(_vm.isModalOpen)?_c('more-info-modal',{attrs:{"bodyText":_vm.modalText,"headingText":_vm.modalHeading},on:{"close":_vm.hideMoreInfoModal}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }