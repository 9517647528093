<script>
const statusText = {
  paymentRequired: 'Payment required',
  completeClientQuestionnaire: 'Client Questionnaire Required',
  notStarted: 'Not Started',
  inProgress: 'In Progress',
  available: 'Available',
  completed: 'Submitted',
  termsOfBusinessConfirmation: 'Please Accept Terms Of Business',
  actionRequired: 'Action Required',
  processing: 'Processing',
  additional: 'Additional',
};

export default {
  name: 'TaskStatusPill',
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusText,
    };
  },
  computed: {
    style: function () {
      switch (this.task.status) {
        case 'completed':
          return 'badge-success';
        case 'inProgress':
        case 'available':
          return 'badge-info';
        case 'paymentRequired':
        case 'termsOfBusinessConfirmation':
          return 'badge-danger';
        case 'completeClientQuestionnaire':
          return 'badge-danger';
        case 'additional':
          return 'badge-light';
        default:
          return 'badge-dark';
      }
    },
  },
};
</script>

<template>
  <h4>
    <span :class="`${style} badge badge-pill border-3 mr-1 mb-1 text-uppercase`">{{ statusText[task.status] }}</span>
  </h4>
</template>
