<script>
import UploadedFilesList from '@/components/documentManager/UploadedFilesList';

import getTrackingEvents from '@/api/trackingEvents/getTrackingEvents';
import calculateTaskStatus from '@/functions/calculateTaskStatus';
import Loader from '@/components/Loader';
import getTask from '@/functions/getTask';
import remortgageClientQuestionnaire from '@/functions/remortgage/clientQuestionnaire';
import supportingDocuments from '@/functions/tasks/supportingDocuments';
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/Modal';
import CompletedQuestionnaire from '@/components/messaging/CompletedRemortgageQuestionnaire';
import TaskStatusButton from '@/components/TaskStatusButton';
import remortgageTrackingEvents from '@/values/remortgageTrackingEvents';
import TaskStatusPill from '@/components/TaskStatusPill';
import getRemortgage from '@/api/remortgages/getRemortgage';
import RemortgageProgress from '@/components/RemortgageProgress';
import CompletedCaseTasks from '@/components/messaging/CompletedCaseTasks';
import UploadSupportingDocuments from '@/components/messaging/UploadSupportingDocuments';

// @TODO: temporary mock document, completion message

export default {
  name: 'ViewRemortgage',
  components: {
    TaskStatusPill,
    TaskStatusButton,
    UploadedFilesList,
    Loader,
    Modal,
    CompletedQuestionnaire,
    RemortgageProgress,
    CompletedCaseTasks,
    UploadSupportingDocuments,
  },

  props: ['entityType', 'entityId'],
  computed: {
    ...mapState({
      trackingEvents: (state) => state.remortgage.trackingEvents,
      eventToHandle: (state) => state.remortgage.eventToHandle,
    }),
    caseReferenceNumber: function () {
      return this.entity ? this.entity?.sort_refer_id : '';
    },
    caseAddress: function () {
      if (this.entity) {
        return `${this.entity?.address?.premise} ${this.entity?.address?.thoroughfare}, ${this.entity?.address?.postal_code}`;
      }
      return '';
    },
    caseStatus: function () {
      if (this.entity) {
        switch (this.entity?.status) {
          case 'created':
            return 'Active';
          case 'completed':
            return 'Completed';
          case 'cancelled':
            return 'Cancelled';
          case 'declined':
            return 'Declined';
          case 'suspended':
            return 'Suspended';
          default:
            return 'Unknown';
        }
      }
      return '';
    },
  },
  data() {
    return {
      entity: null,
      loader: false,
      showUserMessage: false,
      userMessageTemplate: false,
      delayRemortgageProgressLoad: false,
      tasks: [getTask(remortgageClientQuestionnaire, { whatsNextInfo: true }), getTask(supportingDocuments)],
      refreshAttemptsRemaining: 10,
    };
  },
  watch: {
    trackingEvents() {
      this.tasks = calculateTaskStatus(this.trackingEvents, this.tasks);
    },
  },
  methods: {
    ...mapActions('remortgage', ['setTrackingEvents', 'addTrackingEvent', 'setEventToHandle']),
    lockedButton(value) {
      return this.caseStatus !== 'Active' && this.caseStatus !== 'Completed' && value?.path !== 'supportingDocuments';
    },
    hideModalWithCompletionMessage(value) {
      this.showUserMessage = value;
    },
    showWhatsNextInfo(event) {
      this.userMessageTemplate = event;
      this.showUserMessage = true;
    },
    findLastCompletedEvent() {
      // Last completed event will be without CreatedAt value
      const result = this.trackingEvents.filter((obj) => typeof obj.CreatedAt === 'undefined').pop();
      return result ? result.event_slug : false;
    },
    async updateTrackingEvents(updateTasksIfUnchanged) {
      try {
        const response = await getTrackingEvents(this.entityId, Object.values(remortgageTrackingEvents));
        // We need to set tracking events only when store qty is smaller or the same,
        // otherwise sqs delay will remove last added tracking event from store
        const isNewEntity = response.data?.results[0]?.EntityId !== this.entityId;
        const shouldOverwriteStore = this.trackingEvents.length <= response.data.count;
        if (isNewEntity || shouldOverwriteStore) {
          this.setTrackingEvents(response.data.results);
        } else if (updateTasksIfUnchanged) {
          this.tasks = calculateTaskStatus(this.trackingEvents, this.tasks);
        }
      } catch (e) {
        console.error(e);
      }

      // If one or more tasks is 'processing', refresh the tracking events
      // list in a few seconds' time.
      await this.$nextTick(); // allow trackingEvents() 'watch' to complete
      if (this.tasks.some((task) => task.status === 'processing') && this.refreshAttemptsRemaining-- > 0) {
        setTimeout(async () => {
          await this.updateTrackingEvents(false);
        }, 3000);
      }
    },
  },
  async mounted() {
    this.loader = true;

    await this.updateTrackingEvents(true);

    this.entity = await getRemortgage(this.entityId).then((res) => res.data);

    // Show What's next relevant message straight away after form completion
    if (this.eventToHandle === 'completed_questionnaire') {
      this.delayRemortgageProgressLoad = true;
      this.showWhatsNextInfo(this.eventToHandle);
    } else if (this.entity?.status === 'completed') {
      // Case Tasks Completed - ALWAYS display dialogue informing what happens next
      this.sectionLoad = 'completed_case_tasks';
      this.showWhatsNextInfo('completed_case_tasks');
    }

    this.loader = false;
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <loader v-if="loader">Loading Tasks...</loader>
          <div class="col-md-12" v-else>
            <!-- Main Task Header -->
            <div class="row card-header bg-light header-elements-inline">
              <h3 class="card-title my-0"><strong>Case Reference: </strong> {{ caseReferenceNumber }}</h3>
              <h3 class="card-title my-0"><strong>Address: </strong> {{ caseAddress }}</h3>
              <h3 class="card-title my-0">
                <strong>Onboarding Status: </strong>
                <span
                  v-if="this.caseStatus === 'Active'"
                  class="badge-danger badge badge-pill border-3 mr-1 mb-1 text-uppercase"
                  >Incomplete</span
                >
                <span
                  v-else-if="this.caseStatus === 'Completed'"
                  class="badge-success badge badge-pill border-3 mr-1 mb-1 text-uppercase"
                  >{{ this.caseStatus }}</span
                >
                <span v-else class="badge-danger badge badge-pill border-3 mr-1 mb-1 text-uppercase">
                  {{ this.caseStatus }}
                </span>
              </h3>
            </div>

            <div class="row align-items-center p-2 d-none d-md-flex">
              <div class="col-sm-12 col-md-6">
                <h3 class="d-flex justify-content-center justify-content-md-start">Name</h3>
              </div>
              <div class="col-md-2">
                <h3>Status</h3>
              </div>
            </div>
            <!-- /main task header -->

            <!-- Display each task -->
            <div v-for="(task, taskKey) in tasks" :key="taskKey">
              <div v-show="task.visible">
                <div class="row d-flex align-items-center border-top p-2" :key="taskKey">
                  <!-- Name -->
                  <div class="col-sm-12 col-md-6">
                    <div class="d-flex justify-content-center justify-content-md-start text-center">
                      <h3>
                        <button
                          type="button"
                          class="d-none d-md-inline btn-info btn-circle border-1 btn-md mr-3 align-middle bg-primary"
                        >
                          {{ task.icon }}
                        </button>

                        <span href="#" class="text-default font-weight-semibold letter-icon-title align-middle">
                          {{ task.name }}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <!-- /end name -->

                  <!-- Status -->
                  <div class="col-sm-12 col-md-2">
                    <div class="d-flex justify-content-center justify-content-md-start">
                      <div class="status">
                        <task-status-pill :task="task"></task-status-pill>
                      </div>
                    </div>
                  </div>
                  <!-- /end status -->

                  <!-- What's next info -->
                  <div class="col-sm-12 col-md-2">
                    <h4 class="d-flex justify-content-center justify-content-md-start">
                      <span
                        v-if="
                          (task.whatsNextInfo === true && task.status === 'completed') || task.status === 'additional'
                        "
                        class="cursor-pointer text-info text-nowrap ml-md-3"
                        @click="showWhatsNextInfo(task.trackingEvents.completedEvent)"
                      >
                        <i class="icon-info22 mr-2 icon"></i>
                        <span v-if="task.status == 'additional'">Info</span>
                        <span v-else>What's next?</span>
                      </span>
                    </h4>
                  </div>
                  <!-- /end what's next info -->

                  <!-- Button -->
                  <div class="col-sm-12 col-md-2">
                    <div class="d-flex justify-content-center justify-content-md-end">
                      <task-status-button
                        :task="task"
                        :entity-type="entityType"
                        :entity-id="entityId"
                        :locked="lockedButton(task)"
                      />
                    </div>
                  </div>
                  <!-- button -->
                </div>
                <div v-if="entity !== undefined && entity !== null">
                  <remortgage-progress
                    v-if="task.event_slug === 'client_questionnaire' && entity !== null"
                    :moreInfoLinksVisible="task.status === 'completed'"
                    :delayLoad="delayRemortgageProgressLoad"
                    :entity-type="entityType"
                    :entity-id="entityId"
                    :event-to-handle="eventToHandle"
                    :client-ids="entity.client_ids"
                    :locked="lockedButton(task)"
                    :suppressed-services="entity.config.suppress_services || []"
                    :task-status="task.status"
                  />
                </div>
              </div>
            </div>
            <!-- /display each task -->

            <div v-if="caseStatus != 'Completed'" class="row border-top">
              <div class="col-12 text-center p-3">
                <span class="text-danger">
                  We will not start the legal work on your case until you have fully completed all the tasks set out in
                  our portal.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-xl-12">
        <uploaded-files-list :entityId="entityId" entityType="remortgage" :entity="entity" />
      </div>
    </div>

    <modal
      v-if="showUserMessage && this.entity && this.entity.config"
      :show-buttons="true"
      closeButtonText="Close"
      :fullModalWidth="false"
      @proceed="hideModalWithCompletionMessage"
    >
      <completed-questionnaire v-if="userMessageTemplate === 'completed_questionnaire'" :config="this.entity.config" />
      <upload-supporting-documents
        v-if="userMessageTemplate === 'upload_supporting_documents'"
        :config="this.entity.config"
      />
      <completed-case-tasks
        v-else-if="userMessageTemplate === 'completed_case_tasks'"
        :config="this.entity.config"
        :entity-type="entityType"
        :case-reference-number="caseReferenceNumber"
      />
    </modal>
  </div>
</template>
<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  h3,
  h4,
  span {
    margin: 0;
    padding: 5px;
  }
}
</style>
