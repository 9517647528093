<script>
import Modal from '@/components/Modal';

export default {
  components: { Modal },
  name: 'IframeModal',
  props: ['src', 'visible'],
};
</script>
<template>
  <div class="col-6 col-md-2">
    <modal
      v-if="visible"
      :fullModalWidth="true"
      :remove-section-spacing="true"
      :show-buttons="true"
      :show-header="false"
      class="iframe-modal"
      closeButtonText="Close"
      @proceed="$emit('dismiss')"
    >
      <iframe :src="src" allowfullscreen height="650px" scrolling="yes" style="border: none" width="100%"></iframe>
    </modal>
  </div>
</template>

<style>
.iframe-modal .modal-body {
  padding: 1.25rem 2px;
}
</style>
