<script>
export default {
  name: 'CompletedTA10',
  props: {
    config: {
      type: Object,
      required: false,
    },
  },
  methods: {
    serviceSuppressed(service) {
      if (this.config === null) {
        return false;
      }

      return this.config?.suppress_services?.indexOf(service) > -1; // Means exist in array
    },
  },
  data() {
    return {
      currentStep: 1,
      checklistImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('dashboard.checklistIcon')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundSize: '10rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '10rem',
      },
      logoImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('site.logo')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '5rem',
      },
      // These must be in the order they appear on the page.
      sections: {
        Yoti: 0,
        DocumentUpload: 0,
      },
    };
  },
  mounted() {
    let i = 1;
    Object.keys(this.sections).forEach((k) => {
      this.sections[k] = this.serviceSuppressed(k) ? 0 : i++;
    });
  },
};
</script>

<template>
  <div id="page-content">
    <h2>Fittings and Contents Form (TA10) completed</h2>

    <div class="my-2" :style="checklistImg"></div>

    <p class="my-2"><strong>Thank you for completing your Fittings and Contents Form (TA10)</strong></p>

    <p class="my-2 heading-color">
      <strong>Here is what you need to complete to finish your {{ $t('site.processName') }} process:</strong>
    </p>

    <section v-if="!serviceSuppressed('Yoti')">
      <div class="row col-12">
        <div class="col-2">
          <button type="button" class="btn-round border-2">{{ this.sections.Yoti }}</button>
        </div>
        <div class="col-10">
          <p>
            <strong>Digitally sign your Fittings and Contents Form through YotiSign –</strong> Please check your email
            inbox (including Spam/Junk folders) for an invite from YotiSign with attached copy of your Fittings and
            Contents Form. Please follow the simple process to digitally sign these documents. Each person listed on the
            title deed will receive an invitation to sign and must individually complete this process.
          </p>

          <p />
        </div>
      </div>
    </section>

    <div class="border-bottom my-4" />

    <div class="row col-12 my-4">
      <div class="col-2">
        <button type="button" class="btn-round border-2">{{ this.sections.DocumentUpload }}</button>
      </div>
      <div class="col-10">
        <strong>Upload any supporting documents –</strong> please ensure you upload any documents you have referred to
        as part of filling in your forms (for example Guarantee documents or Building Regulations) to the document
        manager. These will accompany your completed forms.
      </div>
    </div>

    <div class="border-bottom my-4" />

    <section v-if="!serviceSuppressed('Yoti')">
      <div class="row col-12 my-4">
        <div class="col-2">
          <button type="button" class="btn-round border-2">3</button>
        </div>
        <div class="col-10">
          Ensure ID has been verified for all people listed on the title deed and that all completed forms have been
          digitally signed using YotiSign.
        </div>
      </div>
    </section>

    <p class="my-2">
      <strong
        >Thank you for completing the {{ $t('site.processName') }} process. Once you have a buyer ready, your
        Conveyancing process can be worked on straight away.</strong
      >
    </p>

    <p class="my-2 heading-color"><strong>Good luck with your Sale</strong></p>

    <div :style="logoImg"></div>
  </div>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
  text-align: center;
}

.modal-container {
  width: 100% !important;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}

.btn-round {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 35px;
  line-height: 1.33;
  pointer-events: none;
  background-color: white;
}
</style>
