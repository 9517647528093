<script>
export default {
  name: 'CompletedSaleQuestionnaire',
  props: {
    config: {
      type: Object,
      required: false,
    },
  },
  methods: {
    serviceSuppressed(service) {
      if (this.config === null) {
        return false;
      }
      return this.config?.suppress_services?.indexOf(service) > -1; // Means exist in array
    },
  },
  data() {
    return {
      currentStep: 1,
      checklistImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('dashboard.checklistIcon')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundSize: '10rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '10rem',
      },
      logoImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('site.logo')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '5rem',
      },
      // These must be in the order they appear on the page.
      sections: {
        Credas: 0,
        Yoti: 0,
        NextForm: 0,
      },
    };
  },
  mounted() {
    let i = 1;
    Object.keys(this.sections).forEach((k) => {
      this.sections[k] = this.serviceSuppressed(k) ? 0 : i++;
    });
  },
};
</script>

<template>
  <div id="page-content">
    <h2>Client questionnaire completed</h2>

    <div :style="checklistImg" class="my-2"></div>

    <p class="my-2"><strong>Thank you for completing your Client Questionnaire!</strong></p>

    <p class="my-2 heading-color"><strong>Here is what you need to complete next:</strong></p>
    <section v-if="!serviceSuppressed('Credas')">
      <div class="row col-12">
        <div class="col-2">
          <button class="btn-round border-2" type="button">{{ this.sections.Credas }}</button>
        </div>
        <div class="col-10">
          <p>
            <strong>Verify your ID – through Credas -</strong> you have been sent an SMS/Email with an invite to
            download the 'Credas' app to verify your identification. Each person listed on the title deed must complete
            this process.
          </p>
          <p>
            <strong>Note:</strong> If you would like to understand more about Credas and the process, please click to
            watch our Credas ID guide
          </p>

          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            frameborder="0"
            height="360"
            src="https://www.youtube.com/embed/-N8Rd24_Zmc"
            width="100%"
          ></iframe>
          <p class="d-block d-sm-block d-md-none">
            <a href="https://www.youtube.com/watch?v=-N8Rd24_Zmc">Watch Credas Video Guide</a>
          </p>
        </div>
      </div>
    </section>

    <section v-if="!serviceSuppressed('Yoti')">
      <div class="border-bottom my-4" />
      <div class="row col-12">
        <div class="col-2">
          <button class="btn-round border-2" type="button">{{ this.sections.Yoti }}</button>
        </div>
        <div class="col-10">
          <strong>Digitally sign your Client Questionnaire through YotiSign –</strong> Please check your email inbox
          (including Spam/Junk folders) for an invite from YotiSign with attached copy of your Client Questionnaire.
          Please follow the simple process to digitally sign these documents. Each person listed on the title deed will
          receive an invitation to sign and must individually complete this process.
        </div>
      </div>
    </section>

    <div class="border-bottom my-4" />

    <div class="row col-12 my-4">
      <div class="col-2">
        <button class="btn-round border-2" type="button">{{ this.sections.NextForm }}</button>
      </div>
      <div class="col-10">
        Please move on to complete your <strong>next online form</strong> and submit once you’ve completed all fields.
      </div>
    </div>

    <div :style="logoImg"></div>
  </div>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
  text-align: center;
}

.modal-container {
  width: 100% !important;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}

.btn-round {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 35px;
  line-height: 1.33;
  pointer-events: none;
  background-color: white;
}
</style>
