<script>
import { mapMutations } from 'vuex';
import * as saleMutations from '@/store/sale/mutations';
import * as purchaseMutations from '@/store/purchase/mutations';
import * as remortgageMutations from '@/store/remortgage/mutations';
import getIdCheck from '@/api/hooyu/getIdCheck';
import IframeModal from './common/IframeModal.vue';
import EventButton from './common/EventButton.vue';

export default {
  name: 'HooYu',
  components: { EventButton, IframeModal },
  data() {
    return {
      contentUrl: '',
      loading: false,
      mode: 'link',
      showModal: false,
    };
  },
  props: [
    'client',
    'clientId',
    'entityId',
    'entityType',
    'eventKey',
    'events',
    'eventName',
    'existingTrackingEvents',
    'parentLoading',
    'taskStatus',
    'locked',
  ],
  methods: {
    ...mapMutations([
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_EVENT_TO_HANDLE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_EVENT_TO_HANDLE}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EVENT_TO_HANDLE}`,
    ]),
    dismissModal() {
      this.loading = true;
      this.showModal = false;
      this.$emit('reload', this.eventKey);
    },
    async showContent() {
      if (this.mode !== 'modal') {
        // Set event to handle as we need to set loader on selected button and await for status
        if (this.entityType === 'sale') {
          this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_EVENT_TO_HANDLE}`](
            `requested-key:${this.eventKey}`,
          );
        } else if (this.entityType === 'purchase') {
          this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_EVENT_TO_HANDLE}`](
            `requested-key:${this.eventKey}`,
          );
        } else if (this.entityType === 'remortgage') {
          this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EVENT_TO_HANDLE}`](
            `requested-key:${this.eventKey}`,
          );
        }

        this.navigate('EmbeddedHooYu', { eventId: this.eventId, eventType: this.eventType, clientId: this.clientId });
        return;
      }
      this.loading = true;
      this.contentUrl = (await getIdCheck(this.entityType, this.entityId, this.clientId)).link;
      this.showModal = true;
      this.loading = false;
    },
    fullName() {
      return [this.client?.first_name, this.client?.middle_name, this.client?.last_name].filter((v) => v).join(' ');
    },
    isComplete() {
      const isComplete = !!this.existingTrackingEvents?.find(({ event_slug, metadata }) => {
        return (
          event_slug === this.events.completed ||
          (event_slug === this.events.individualCompleted && metadata?.person_id === this.clientId)
        );
      });
      if (isComplete) {
        this.loading = false;
      }

      return isComplete;
    },
    isStarted() {
      return !!this.existingTrackingEvents?.find(({ event_slug, metadata }) => {
        return event_slug === this.events.individualStarted && metadata?.person_id === this.clientId;
      });
    },
    isParentComplete() {
      if (this.taskStatus == 'completed') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.parentLoading) {
      this.loading = true;
    }
  },
  watch: {
    parentLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<template>
  <div v-if="isParentComplete()" class="row d-flex align-items-center border-top p-2 bg-light">
    <div class="col-sm-12 col-md-6">
      <div class="d-flex justify-content-center justify-content-md-start">
        <h4 class="text-default font-weight-semibold letter-icon-title text-center">
          <span class="pl-3"><i class="icon-person mr-2" /></span>
          {{ eventName }} {{ this.fullName() }}
        </h4>
      </div>
    </div>

    <div class="col-sm-12 col-md-2">
      <h4 class="d-flex justify-content-center justify-content-md-start">
        <div class="status text-uppercase">
          <span :class="`badge-${isComplete() ? 'success' : 'danger'} badge badge-pill border-3 mr-1 mb-1`">
            {{ isComplete() ? 'Submitted' : 'Incomplete' }}
          </span>
        </div>
      </h4>
    </div>

    <div class="col-sm-12 col-md-2">
      <h4 v-if="!isComplete()" class="d-flex justify-content-center justify-content-md-start text-nowrap">
        <span
          class="cursor-pointer text-info d-inline ml-md-3"
          @click="$emit('show-more-info-modal', { eventName: events.individualCompleted })"
        >
          <i class="icon-info22 mr-2 icon"></i>
          <span>More info</span>
        </span>
      </h4>
    </div>

    <div class="col-sm-12 col-md-2">
      <event-button
        v-if="!isComplete() && isStarted()"
        :completed="isComplete()"
        :disabled="locked"
        :loading="loading"
        v-on:clicked="() => showContent()"
      >
        <span v-if="this.loading">Checking ID/AML Status...</span>
        <span v-else>{{ isComplete() ? 'Complete' : 'Continue' }}</span>
      </event-button>
      <iframe-modal
        v-if="!isComplete() && isStarted()"
        :src="contentUrl"
        :visible="showModal"
        v-on:dismiss="() => dismissModal()"
      ></iframe-modal>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  h3,
  h4,
  span {
    margin: 0;
    padding: 5px;
  }
}
</style>
