<script>
import Modal from '@/components/Modal';

export default {
  name: 'MoreInfoModal',
  components: { Modal },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  props: {
    headingText: {
      type: String,
      required: true,
    },
    bodyText: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <modal closeButtonText="Close" @proceed="close()">
    <div class="text-center">
      <i class="icon-info3 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
      <h3>{{ headingText }}</h3>
      <div style="font-size: 1rem" v-html="bodyText" />
    </div>
  </modal>
</template>
