import { UPLOAD_SUPPORTING_DOCUMENTS } from '@/values/trackingEvents';

export default {
  name: 'Upload Supporting Documents',
  path: 'supportingDocuments',
  icon: 'SD',
  event_slug: 'upload_supporting_documents',
  trackingEvents: {
    completedEvent: UPLOAD_SUPPORTING_DOCUMENTS,
  },
  visible: true,
  active: false,
  status: 'additional',
  whatsNextInfo: true,
};
