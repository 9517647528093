import { TA7_COMPLETED, TA7_STARTED } from '@/values/trackingEvents';

export default {
  name: 'Sellers Leasehold Information',
  path: 'Ta7Form',
  icon: 'TA7',
  event_slug: 'sellers_leaseholds_information',
  trackingEvents: {
    startedEvent: TA7_STARTED,
    completedEvent: TA7_COMPLETED,
  },
  visible: true,
  active: false,
  status: false,
  whatsNextInfo: false,
};
