import { PAYMENT_COMPLETED } from '@/values/trackingEvents';

export default {
  name: 'Fast Start - Payment',
  path: 'Payments',
  icon: 'P',
  event_slug: 'fast_start_pack_payment',
  trackingEvents: {
    // Here we set a tracking event expected to start or complete task
    completedEvent: PAYMENT_COMPLETED,
  },
  visible: true,
  active: false,
  status: false,
  whatsNextInfo: false,
};
