<script>
import ViewPurchase from '@/pages/purchase/ViewPurchase';
import ViewSale from '@/pages/sale/ViewSale';
import ViewRemortgage from '@/pages/remortgage/ViewRemortgage';
import getCaseSummaryById from '@/api/getCaseSummaryById';
import Loader from '@/components/Loader.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ViewEntity',
  components: { Modal, Loader, ViewPurchase, ViewSale, ViewRemortgage },
  data: function () {
    return { isLegacy: true, loading: true, summary: undefined };
  },
  methods: {
    redirectToMyCases() {
      location.href = '/my-cases';
    },
    redirectToNewOnboarding() {
      location.href = `${process.env.VUE_APP_NEW_FRONTEND}/case/${this.summary.entity_id}/${this.summary.case_reference}`;
    },
  },
  props: ['entityType', 'entityId'],
  async beforeMount() {
    this.summary = await getCaseSummaryById(this.entityId);
    this.isLegacy = this.summary?.config?.is_legacy === true;
    this.loading = false;
  },
};
</script>

<template>
  <loader v-if="loading">Loading Tasks...</loader>
  <div v-else-if="!summary" class="col-md-12">
    <modal :fullModalWidth="false" :show-buttons="true" proceedButtonText="OK" @proceed="redirectToMyCases">
      We were unable to load your case information. Please click ok to be redirected to your case list.
    </modal>
  </div>
  <div v-else-if="!isLegacy" class="col-md-12">
    <modal :fullModalWidth="false" :show-buttons="true" proceedButtonText="OK" @proceed="redirectToNewOnboarding">
      The case you are viewing must be completed in our new onboarding system. Please click ok to be redirected.
    </modal>
  </div>
  <div v-else-if="isLegacy">
    <ViewSale v-if="entityType === 'sale'" :entityId="entityId" :entityType="entityType" />
    <ViewPurchase v-if="entityType === 'purchase'" :entityId="entityId" :entityType="entityType" />
    <ViewRemortgage v-if="entityType === 'remortgage'" :entityId="entityId" :entityType="entityType" />
  </div>
</template>
<style lang="scss" scoped></style>
