<script>
export default {
  name: 'CompletedPurchaseQuestionnaire',
  props: {
    config: {
      type: Object,
      required: false,
    },
  },
  methods: {
    serviceSuppressed(service) {
      if (this.config === null) {
        return false;
      }

      return this.config?.suppress_services?.indexOf(service) > -1; // Means exist in array
    },
    printCurrentStep() {
      const currentValue = this.currentStep;
      this.currentStep += 1;
      return currentValue;
    },
  },
  data() {
    return {
      currentStep: 1,
      checklistImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('dashboard.checklistIcon')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundSize: '10rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '10rem',
      },
      logoImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('site.logo')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '5rem',
      },
    };
  },
};
</script>

<template>
  <div id="page-content">
    <h2>Client questionnaire completed</h2>

    <div class="my-2" :style="checklistImg"></div>

    <p class="my-2"><strong>Thank you for completing your Client Questionnaire!</strong></p>

    <p class="my-2 heading-color"><strong>Here is what you need to complete next:</strong></p>

    <section v-if="!serviceSuppressed('Yoti')">
      <div class="row col-12">
        <div class="col-2">
          <button type="button" class="btn-round border-2">1</button>
        </div>
        <div class="col-10">
          <strong>Digitally sign your Client Questionnaire through YotiSign –</strong> Please check your email inbox
          (including Spam/Junk folders) for an invite from YotiSign with attached copy of your Client Questionnaire.
          Please follow the simple process to digitally sign these documents. Each person listed on the title deed will
          receive an invitation to sign and must individually complete this process.
        </div>
      </div>
    </section>

    <div :style="logoImg"></div>
  </div>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
  text-align: center;
}

.modal-container {
  width: 100% !important;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}

.btn-round {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 35px;
  line-height: 1.33;
  pointer-events: none;
  background-color: white;
}
</style>
