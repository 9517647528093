<script>
export default {
  name: 'upload_supporting_documents',
  props: {
    config: {
      type: Object,
      required: false,
    },
  },
  methods: {
    serviceSuppressed(service) {
      if (this.config === null) {
        return false;
      }
      return this.config?.suppress_services?.indexOf(service) > -1; // Means exist in array
    },
  },
  data() {
    return {
      currentStep: 1,
      checklistImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('dashboard.checklistIcon')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundSize: '10rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '10rem',
      },
      logoImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('site.logo')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '5rem',
      },
    };
  },
};
</script>

<template>
  <div id="page-content">
    <h2>Upload Supporting Documents</h2>

    <div :style="checklistImg" class="my-2"></div>

    <p class="my-2">
      <strong
        >Based on your answers to the client questionnaire and information forms, supporting documents are
        required.</strong
      >
    </p>
    <p class="my-2">
      Making these documents available at this stage will ensure all parties have the information they need to progress
      as swiftly as possible. This list serves only as a reminder of your answers and to help collate the necessary
      documentation and
      <strong>will not prevent your case from progressing if you are unable to provide certain documents</strong>.
    </p>

    <div :style="logoImg"></div>
  </div>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
  text-align: center;
}

.modal-container {
  width: 100% !important;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}

.btn-round {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 35px;
  line-height: 1.33;
  pointer-events: none;
  background-color: white;
}
</style>
