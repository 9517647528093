<script>
export default {
  name: 'completed_case_tasks',
  props: {
    config: {
      type: Object,
      required: false,
    },
    entityType: {
      type: String,
      required: true,
    },
    caseReferenceNumber: {
      type: String,
      required: true,
    },
  },
  methods: {
    serviceSuppressed(service) {
      if (this.config === null) {
        return false;
      }
      return this.config?.suppress_services?.indexOf(service) > -1; // Means exist in array
    },
  },
  data() {
    return {
      currentStep: 1,
      checklistImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('dashboard.checklistIcon')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundSize: '10rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '10rem',
      },
      logoImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('site.logo')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '5rem',
      },
    };
  },
};
</script>

<template>
  <Transition name="slide-fade">
    <div id="page-content">
      <h2>Onboarding Completed</h2>

      <div :style="checklistImg" class="my-2"></div>

      <div v-if="entityType === 'sale' || entityType === 'purchase'">
        <p class="my-2">
          <strong>Thank you for completing all the steps in our onboarding portal. </strong>
        </p>
        <p class="my-2">
          Your documentation will now be reviewed by our transactional new business team.
          <strong>They will be in touch shortly if anything else is required</strong>.
        </p>
        <p class="my-2">
          Once your documents and ID are approved the team will contact you with details of your case handler.
        </p>
        <p class="my-2">
          Please do contact us if you have any queries <br />using case reference {{ caseReferenceNumber }}
        </p>
      </div>
      <div v-else-if="entityType === 'remortgage'">
        <p class="my-2">
          <strong>Thank you for completing all the steps in our onboarding portal. </strong>
        </p>
        <p class="my-2">
          Your documentation will be reviewed by our remortgage new business team and they will be in touch if any other
          documents are required.
        </p>
        <p class="my-2">
          <strong
            >Once your mortgage offer is issued <span class="text-secondary">(if not already)</span> the team will be in
            touch with details of your allocated case handler.</strong
          >
        </p>
        <p class="my-2">
          In the meantime, if you have any queries please do not hesitate to contact us <br />using case reference
          {{ caseReferenceNumber }}
        </p>
      </div>
      <div v-else>
        <p class="my-2">
          <strong>Thank you for completing all the steps in our onboarding portal. </strong>
        </p>
        <p class="my-2">
          Your documentation will now be reviewed by our new business team.
          <strong>They will be in touch shortly if anything else is required</strong>.
        </p>
        <p class="my-2">
          Once your documents are approved the team will contact you with details of your case handler.
        </p>
        <p class="my-2">
          Please do contact us if you have any queries <br />using case reference {{ caseReferenceNumber }}
        </p>
      </div>
      <div :style="logoImg"></div>
    </div>
  </Transition>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
  text-align: center;
}

.modal-container {
  width: 100% !important;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}

.btn-round {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 35px;
  line-height: 1.33;
  pointer-events: none;
  background-color: white;
}
</style>
