import {
  CLIENT_QUESTIONNAIRE_COMPLETED,
  CLIENT_QUESTIONNAIRE_STARTED,
  HMLR_TRACKING_EVENT_STARTED,
  HMLR_TRACKING_EVENT_COMPLETED,
} from '@/values/trackingEvents';

export default {
  name: 'Client Questionnaire',
  path: 'RemortgageClientQuestionnaire',
  icon: 'CQ',
  event_slug: 'client_questionnaire',
  trackingEvents: {
    startedEvent: CLIENT_QUESTIONNAIRE_STARTED,
    pendingEvent: HMLR_TRACKING_EVENT_STARTED,
    furtherInputNeededEvent: HMLR_TRACKING_EVENT_COMPLETED,
    completedEvent: CLIENT_QUESTIONNAIRE_COMPLETED,
  },
  visible: true,
  active: false,
  status: false,
  whatsNextInfo: false,
};
