<script>
export default {
  name: 'EventButton',
  props: ['completed', 'loading', 'disabled'],
};
</script>
<template>
  <button
    :class="`bg-${completed ? 'success' : 'primary'} btn btn-sm`"
    style="width: 100% !important; min-width: 0 !important"
    type="button"
    :disabled="completed || loading || disabled"
    @click="$emit('clicked')"
  >
    <i v-if="loading" class="icon-spinner11 mr-2 spinner"></i>
    <i v-else :class="`icon-${completed ? 'check' : 'play3'} mr-2`"></i>
    <slot></slot>
  </button>
</template>

<style>
.iframe-modal .modal-body {
  padding: 1.25rem 2px;
}
</style>
