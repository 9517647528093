<script>
import PostTrackingEvents from '@/api/trackingEvents/postTrackingEvents';

export default {
  name: 'TaskStatusButton',
  props: {
    locked: {
      type: Boolean,
      default: false,
    },
    entityId: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    disabled: function () {
      let status = this.determineTaskStatus();
      return this.locked || status === 'locked' || status === 'completed';
    },
    style: function () {
      return this.determineTaskStatus() === 'completed' ? 'bg-success' : 'bg-primary';
    },
    // eslint-disable-next-line vue/return-in-computed-property
    icon: function () {
      switch (this.determineTaskStatus()) {
        case 'locked':
          return 'icon-lock';
        case 'completed':
          return 'icon-check';
        case 'view':
          return 'icon-search4';
        case 'inProgress':
        case 'notStarted':
          return 'icon-play3';
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    text: function () {
      switch (this.determineTaskStatus()) {
        case 'locked':
          return 'Locked';
        case 'inProgress':
          return 'Continue';
        case 'completed':
          return 'Done';
        case 'view':
          return 'View';
        case 'notStarted':
          return 'Start';
      }
    },
  },
  methods: {
    addTrackingEvent(trackingEvent) {
      // We can't use mapActions as entity type is dynamic
      this.$store.dispatch(`${this.entityType}/addTrackingEvent`, trackingEvent);
    },
    determineTaskStatus() {
      let status;
      if (!this.task.active) {
        status = 'locked';
      } else if (this.task.status === 'inProgress') {
        status = 'inProgress';
      } else if (this.task.status === 'completed') {
        status = 'completed';
      } else if (this.task.status === 'additional') {
        status = 'view';
      } else {
        status = 'notStarted';
      }

      return status;
    },
    continueTask() {
      this.loading = true;
      this.navigate(this.task.path, {
        entityId: this.entityId,
      });
    },
    async startTask() {
      if (typeof this.task.startAction === 'function') {
        this.loading = true;
        await this.task.startAction();
        this.loading = false;
      }

      if (this.task.event_slug && typeof this.task.trackingEvents.startedEvent !== 'undefined') {
        let trackingEvent = {
          product_slug: this.entityType,
          entity_id: this.entityId,
          event_slug: this.task.trackingEvents.startedEvent,
          metadata: '',
        };

        PostTrackingEvents(trackingEvent)
          .then(() => this.addTrackingEvent(trackingEvent))
          .catch(() => console.error('Could not save tracking event'));
      }

      if (this.task.path !== null && typeof this.task.path !== 'undefined') {
        this.navigate(this.task.path, {
          entityId: this.entityId,
          entityType: this.entityType,
        });
      }
    },
    handleClick() {
      let status = this.determineTaskStatus();
      if (status === 'inProgress') {
        this.continueTask();
      } else if (status === 'notStarted' || status === 'view') {
        this.startTask();
      }
    },
  },
};
</script>

<template>
  <button
    :class="`${style} btn btn-sm`"
    :disabled="disabled"
    style="width: 100% !important; min-width: 0 !important"
    type="button"
    @click="handleClick"
  >
    <i v-if="loading" class="icon-spinner11 mr-2 spinner"></i>
    <i v-else :class="`${icon} mr-2`"></i>
    {{ text }}
  </button>
</template>
