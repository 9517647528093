<script>
import UploadedFilesList from '@/components/documentManager/UploadedFilesList';
import getTrackingEvents from '@/api/trackingEvents/getTrackingEvents';
import acceptTermsOfBusiness from '@/functions/acceptTermsOfBusiness';
import calculateTaskStatus from '@/functions/calculateTaskStatus';
import Loader from '@/components/Loader';
import getTask from '@/functions/getTask';
import termsOfBusiness from '@/functions/tasks/termsOfBusiness';
import fastStartPayment from '@/functions/tasks/fastStartPayment';
import purchaseClientQuestionnaire from '@/functions/purchase/clientQuestionnaire';
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/Modal';
import TermsOfBusiness from '@/components/TermsOfBusiness';
import CompletedQuestionnaire from '@/components/messaging/CompletedPurchaseQuestionnaire';
import CompletedCaseTasks from '@/components/messaging/CompletedCaseTasks';
import TaskStatusButton from '@/components/TaskStatusButton';
import TaskStatusPill from '@/components/TaskStatusPill';
import getPurchase from '@/api/purchases/getPurchase';
import TaskProgress from '@/components/TaskProgress';
import purchaseTrackingEvents, {
  clientQuestionnaireProgressTrackingEvents,
  componentTrackedEvents,
  otherTrackedEvents,
} from '@/values/purchaseTrackingEvents';
import isSortLegalCase from '@/functions/isSortLegalCase';

// @TODO: temporary mock document, completion message

export default {
  name: 'ViewPurchase',
  components: {
    TaskStatusPill,
    TaskStatusButton,
    UploadedFilesList,
    Loader,
    Modal,
    TermsOfBusiness,
    CompletedQuestionnaire,
    TaskProgress,
    CompletedCaseTasks,
  },
  props: ['entityType', 'entityId'],
  computed: {
    ...mapState({
      trackingEvents: (state) => state.purchase.trackingEvents,
      eventToHandle: (state) => state.purchase.eventToHandle,
    }),
    caseReferenceNumber: function () {
      return this.entity ? this.entity?.sort_refer_id : '';
    },
    caseStatus: function () {
      if (this.entity) {
        switch (this.entity?.status) {
          case 'created':
            return 'Active';
          case 'completed':
            return 'Completed';
          case 'cancelled':
            return 'Cancelled';
          case 'declined':
            return 'Declined';
          case 'suspended':
            return 'Suspended';
          default:
            return 'Unknown';
        }
      }
      return '';
    },
  },
  data() {
    return {
      entity: null,
      loader: false,
      showTermsOfBusiness: false,
      showUserMessage: false,
      userMessageTemplate: false,
      sectionDelayLoad: '',
      suppressedDocumentTypes: [],
      tasks: [
        getTask(termsOfBusiness, {
          active: true,
          visible: false,
          startAction: () =>
            new Promise((resolve) => {
              this.showTermsOfBusiness = true;
              resolve();
            }),
        }),
        getTask(fastStartPayment, { active: true }),
        getTask(purchaseClientQuestionnaire, { whatsNextInfo: true }),
      ],
      purchaseProgressTasks: ['client_questionnaire'],
    };
  },
  watch: {
    trackingEvents() {
      this.tasks = calculateTaskStatus(this.trackingEvents, this.tasks);
    },
  },
  methods: {
    ...mapActions('purchase', ['setTrackingEvents', 'addTrackingEvent']),
    acceptTerms(value) {
      if (value) {
        acceptTermsOfBusiness('purchase', this.entityId, (trackingEvent) => {
          this.addTrackingEvent(trackingEvent);
          this.$gtag.event('Completed Milestone', {
            event_category: 'Task',
            event_label: 'Terms Of Business Confirmed',
          });
          this.showTermsOfBusiness = false;
        });
      } else {
        this.showTermsOfBusiness = false;
      }
    },
    hideModalWithCompletionMessage(value) {
      this.showUserMessage = value;
    },
    lockedButton(value) {
      return this.caseStatus !== 'Active' && this.caseStatus !== 'Completed' && value?.path !== 'supportingDocuments';
    },
    showWhatsNextInfo(event) {
      this.userMessageTemplate = event;
      this.showUserMessage = true;
    },
    findLastCompletedEvent() {
      // Last completed event will be without CreatedAt value
      const result = this.trackingEvents.filter((obj) => typeof obj.CreatedAt === 'undefined').pop();
      return result ? result.event_slug : false;
    },
    matchComponentTrackedEvents(eventSlug) {
      if (eventSlug === 'client_questionnaire') {
        return componentTrackedEvents(
          this.entity.client_ids,
          this.entity?.config?.suppress_services || [],
          clientQuestionnaireProgressTrackingEvents,
          this.suppressedDocumentTypes,
        );
      }
      return {};
    },
    matchOtherTrackedEvents(eventSlug) {
      if (eventSlug === 'client_questionnaire') {
        return otherTrackedEvents(
          this.entity?.config?.suppress_services || [],
          clientQuestionnaireProgressTrackingEvents,
          this.suppressedDocumentTypes,
        );
      }
      return {};
    },
    matchProgressTrackingEvents(eventSlug) {
      if (eventSlug === 'client_questionnaire') {
        return clientQuestionnaireProgressTrackingEvents;
      }
      return {};
    },
  },
  async mounted() {
    this.loader = true;

    this.entity = await getPurchase(this.entityId).then((res) => res.data);

    await getTrackingEvents(this.entityId, Object.values(purchaseTrackingEvents))
      .then((response) => {
        // We need to set tracking events only when store qty is smaller or the same,
        // otherwise sqs delay will remove last added tracking event from store
        if (response.data?.results[0]?.EntityId !== this.entityId) {
          this.setTrackingEvents(response.data.results);
        } else if (this.trackingEvents.length <= response.data.count) {
          this.setTrackingEvents(response.data.results);
        } else {
          this.tasks = calculateTaskStatus(this.trackingEvents, this.tasks);
        }
      })
      .catch((err) => console.error(err));

    // Show What's next relevant message straight away after form completion
    if (this.eventToHandle === 'completed_questionnaire') {
      this.sectionDelayLoad = 'client_questionnaire';
      this.showWhatsNextInfo('completed_questionnaire');
    } else if (this.entity?.status === 'completed') {
      // Case Tasks Completed - ALWAYS display dialogue informing what happens next
      this.sectionLoad = 'completed_case_tasks';
      this.showWhatsNextInfo('completed_case_tasks');
    } else {
      this.sectionDelayLoad = '';
    }

    if (this.entity?.config) {
      const config = this.entity.config;

      if (!config?.payment_required) {
        // Change payment task to not visible and recalculate further tasks status
        this.tasks.find((element) => element.path === 'Payments').visible = false;
      }

      if (config?.system === 'Sort Move' && isSortLegalCase(this.entity) && this.entity?.cost_summary !== null) {
        // For SortMove portal cases where supplier is 'Sort Legal' and they are cost summary - display client care pack and ToB
        this.tasks.find((element) => element.event_slug === 'terms_of_business').visible = true;
      } else if (
        config?.system === 'Sort Move' &&
        (isSortLegalCase(this.entity) || this.entity?.cost_summary === null)
      ) {
        // For SortMove portal display Terms of Business and avoid client care pack embedded signing if not Sort Legal or not cost summary provided
        this.suppressedDocumentTypes.push('CLIENT_CARE_PACK');
        this.tasks.find((element) => element.event_slug === 'terms_of_business').visible = true;
      } else {
        // unlock tasks required terms_of_business to unlock
        this.tasks = this.tasks.filter((el) => el.event_slug !== 'terms_of_business');
      }

      this.tasks = calculateTaskStatus(this.trackingEvents, this.tasks);
    }

    this.loader = false;
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <loader v-if="loader">Loading Tasks...</loader>
          <div class="col-md-12" v-else>
            <!-- Main Task Header -->
            <div class="row card-header bg-light header-elements-inline">
              <h3 class="card-title my-0"><strong>Case Reference: </strong> {{ caseReferenceNumber }}</h3>
              <h3 class="card-title my-0">
                <strong>Case Status: </strong>
                <span
                  v-if="this.caseStatus === 'Active'"
                  class="badge-danger badge badge-pill border-3 mr-1 mb-1 text-uppercase"
                  >Incomplete</span
                >
                <span
                  v-else-if="this.caseStatus === 'Completed'"
                  class="badge-success badge badge-pill border-3 mr-1 mb-1 text-uppercase"
                  >{{ this.caseStatus }}</span
                >
                <span v-else class="badge-danger badge badge-pill border-3 mr-1 mb-1 text-uppercase">
                  {{ this.caseStatus }}
                </span>
              </h3>
            </div>

            <div class="row d-flex align-items-center p-3">
              <div class="col-sm-12 col-md-6">
                <h5 class="d-flex justify-content-center justify-content-md-start">Name</h5>
              </div>
              <div class="d-none d-md-block col-md-2">
                <h5>Status</h5>
              </div>
              <div class="d-none d-sm-block col-md-2"></div>
              <div class="d-none d-sm-block col-md-2"></div>
            </div>
            <!-- /main task header -->

            <!-- Display each tasks -->
            <div v-for="(task, taskKey) in tasks" :key="taskKey">
              <div v-show="task.visible">
                <div class="row d-flex align-items-center border-top p-2" :key="taskKey">
                  <!-- Name -->
                  <div class="col-sm-12 col-md-6">
                    <div class="d-flex justify-content-center justify-content-md-start text-center">
                      <h3>
                        <button
                          type="button"
                          class="d-none d-md-inline btn-info btn-circle border-1 btn-md mr-3 align-middle bg-primary"
                        >
                          {{ task.icon }}
                        </button>

                        <span href="#" class="text-default font-weight-semibold letter-icon-title align-middle">
                          {{ task.name }}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <!-- /end name -->

                  <!-- Status -->
                  <div class="col-sm-12 col-md-2">
                    <div class="d-flex justify-content-center justify-content-md-start">
                      <div class="status">
                        <task-status-pill :task="task"></task-status-pill>
                      </div>
                    </div>
                  </div>
                  <!-- /end status -->

                  <!-- What's next info -->
                  <div class="col-sm-12 col-md-2">
                    <h4 class="d-flex justify-content-center justify-content-md-start">
                      <span
                        v-if="task.whatsNextInfo === true && task.status === 'completed'"
                        class="cursor-pointer text-info text-nowrap ml-md-3"
                        @click="showWhatsNextInfo(task.trackingEvents.completedEvent)"
                      >
                        <i class="icon-info22 mr-2 icon"></i>
                        <span class="text-nowrap">What's next?</span>
                      </span>
                    </h4>
                  </div>
                  <!-- /end what's next info -->

                  <!-- Button -->
                  <div class="col-sm-12 col-md-2">
                    <div class="d-flex justify-content-center justify-content-md-end">
                      <task-status-button
                        :task="task"
                        :entity-id="entityId"
                        entity-type="purchase"
                        :locked="lockedButton(task)"
                      />
                    </div>
                  </div>
                  <!-- button -->
                </div>

                <div v-if="entity !== undefined && entity !== null">
                  <task-progress
                    v-if="purchaseProgressTasks.includes(task.event_slug) && entity !== null"
                    :moreInfoLinksVisible="task.status === 'completed'"
                    :entity-type="entityType"
                    :entity-id="entityId"
                    :event-to-handle="eventToHandle"
                    :client-ids="entity.client_ids"
                    :section-delay-load="sectionDelayLoad"
                    :section-event-slug="task.event_slug"
                    :component-tracked-events="matchComponentTrackedEvents(task.event_slug)"
                    :other-tracked-events="matchOtherTrackedEvents(task.event_slug)"
                    :progress-tracking-events="matchProgressTrackingEvents(task.event_slug)"
                    :task-status="task.status"
                    :supplier-information-name="(entity.supplier_information || {}).name"
                  />
                </div>
              </div>
            </div>
            <!-- /display each tasks -->
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-xl-12">
        <uploaded-files-list :entityId="entityId" entityType="purchase" :entity="entity" />
      </div>
    </div>

    <modal
      v-if="showTermsOfBusiness"
      :show-buttons="true"
      closeButtonText="Close"
      proceedButtonText="I have read and agree to the Terms Of Business"
      :fullModalWidth="false"
      :cognitoUserId="this.entityId"
      @proceed="acceptTerms"
    >
      <terms-of-business />
    </modal>

    <modal
      v-if="showUserMessage && this.entity && this.entity.config"
      :show-buttons="true"
      closeButtonText="Close"
      :fullModalWidth="false"
      @proceed="hideModalWithCompletionMessage"
    >
      <completed-questionnaire v-if="userMessageTemplate === 'completed_questionnaire'" :config="this.entity.config" />
      <completed-case-tasks
        v-else-if="userMessageTemplate === 'completed_case_tasks'"
        :config="this.entity.config"
        :entity-type="entityType"
        :case-reference-number="caseReferenceNumber"
      />
    </modal>
  </div>
</template>
<style lang="scss" scoped></style>
