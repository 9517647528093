<script>
export default {
  name: 'CompletedTA7',
  props: {
    config: {
      type: Object,
      required: false,
    },
  },
  methods: {
    serviceSuppressed(service) {
      if (this.config === null) {
        return false;
      }

      return this.config?.suppress_services?.indexOf(service) > -1; // Means exist in array
    },
  },
  data() {
    return {
      currentStep: 1,
      checklistImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('dashboard.checklistIcon')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundSize: '10rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '10rem',
      },
      logoImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('site.logo')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '5rem',
      },
      // These must be in the order they appear on the page.
      sections: {
        Yoti: 0,
        NextForm: 0,
      },
    };
  },
  mounted() {
    let i = 1;
    Object.keys(this.sections).forEach((k) => {
      this.sections[k] = this.serviceSuppressed(k) ? 0 : i++;
    });
  },
};
</script>

<template>
  <div id="page-content">
    <h2>Leasehold Information Form (TA7) completed</h2>

    <div class="my-2" :style="checklistImg"></div>

    <p class="my-2"><strong>Thank you for completing your Leasehold Information Form (TA7)</strong></p>

    <p class="my-2 heading-color"><strong>Here is what you need to complete next:</strong></p>

    <section v-if="!serviceSuppressed('Yoti')">
      <div class="row col-12">
        <div class="col-2">
          <button type="button" class="btn-round border-2">{{ this.sections.Yoti }}</button>
        </div>
        <div class="col-10">
          <p>
            <strong>Digitally sign your Leasehold Information Form through YotiSign –</strong> Please check your email
            inbox (including Spam/Junk folders) for an invite from YotiSign with attached copy of your Leasehold
            Information Form. Please follow the simple process to digitally sign these documents. Each person listed on
            the title deed will receive an invitation to sign and must individually complete this process.
          </p>

          <p />
        </div>
      </div>
    </section>

    <div class="border-bottom my-4" />

    <div class="row col-12 my-4">
      <div class="col-2">
        <button type="button" class="btn-round border-2">{{ this.sections.NextForm }}</button>
      </div>
      <div class="col-10">
        Please move on to complete your <strong>next online form</strong> and submit once you’ve completed all fields.
      </div>
    </div>

    <div :style="logoImg"></div>
  </div>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
  text-align: center;
}

.modal-container {
  width: 100% !important;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}

.btn-round {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 35px;
  line-height: 1.33;
  pointer-events: none;
  background-color: white;
}
</style>
