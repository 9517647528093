import {
  CLIENT_CARE_PACK_SIGNED,
  CLIENT_CARE_PACK_SIGNED_INDIVIDUAL,
  CLIENT_QUESTIONNAIRE_COMPLETED,
  CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL,
  CLIENT_QUESTIONNAIRE_STARTED,
  COMPLETED_AML_INDIVIDUAL,
  HMLR_TRACKING_EVENT_STARTED,
  HMLR_TRACKING_EVENT_COMPLETED,
  ID_CHECK_HOOYU,
  ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL,
  ID_CHECK_HOOYU_STARTED_INDIVIDUAL,
  REMORTGAGE_CLIENT_QUESTIONNAIRE_SIGNED_COMPLETED,
} from '@/values/trackingEvents';
import HooYu from '../components/trackedEvents/HooYu.vue';
import Yoti from '../components/trackedEvents/Yoti.vue';
import trackingEventsHumanName from './trackingEventsHumanName';

export default {
  clientCarePackSignedIndividual: CLIENT_CARE_PACK_SIGNED_INDIVIDUAL,
  clientQuestionnaireStarted: CLIENT_QUESTIONNAIRE_STARTED,
  hmlrTrackingEventStarted: HMLR_TRACKING_EVENT_STARTED,
  hmlrTrackingEventCompleted: HMLR_TRACKING_EVENT_COMPLETED,
  clientQuestionnaireCompleted: CLIENT_QUESTIONNAIRE_COMPLETED,
  completedAmlIndividual: COMPLETED_AML_INDIVIDUAL,
  clientQuestionnaireSignedIndividual: CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL,
};

// This defines the steps shown under "Remortgage Progress"
export const remortgageProgressTrackingEvents = {
  [CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL]: {
    component: Yoti,
    splitPerClient: true,
    yotiSign: true,
    eventName: trackingEventsHumanName[CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL],
    events: {
      individualCompleted: CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL,
      completed: REMORTGAGE_CLIENT_QUESTIONNAIRE_SIGNED_COMPLETED,
    },
    thirdParty: 'Yoti',
    documentName: 'Client Questionnaire',
    documentType: ['CLIENT_QUESTIONNAIRE', 'STANDALONE_TOE_CLIENT_QUESTIONNAIRE'],
    moreInfoHeading: 'Sign Client Questionnaire',
    moreInfoText:
      'Please check your inbox for emails from YotiSign with attached copies of your Client Questionnaire. Please follow the simple process to digitally sign these documents. Each person listed on the title deed must complete this process.',
    moreInfoAlternativeText:
      'Please use the link button provided or check your inbox for emails from YotiSign to access copies of your Client Questionnaire. Follow the simple process to securely digitally sign this document. Each person listed on the title must use their own link or email to complete this process.',
  },
  [CLIENT_CARE_PACK_SIGNED_INDIVIDUAL]: {
    component: Yoti,
    splitPerClient: true,
    yotiSign: true,
    eventName: trackingEventsHumanName[CLIENT_CARE_PACK_SIGNED_INDIVIDUAL],
    events: {
      individualCompleted: CLIENT_CARE_PACK_SIGNED_INDIVIDUAL,
      completed: CLIENT_CARE_PACK_SIGNED,
    },

    thirdParty: 'Yoti',
    documentName: 'Client Care Pack',
    documentType: 'CLIENT_CARE_PACK',
    moreInfoHeading: 'Sign Client Care Pack',
    moreInfoText:
      'Please check your inbox for emails from YotiSign with attached copies of your Client Care Pack. Please follow the simple process to digitally sign these documents. Each person listed on the title deed must complete this process.',
    moreInfoAlternativeText:
      'Please use the link button provided or check your inbox for emails from YotiSign to access copies of your Client Care Pack. Follow the simple process to securely digitally sign this document. Each person listed on the title must use their own link or email to complete this process.',
  },
  [COMPLETED_AML_INDIVIDUAL]: {
    splitPerClient: true,
    yotiSign: false,
    thirdParty: 'Credas',
    documentName: false,
    moreInfoHeading: 'ID/AML Check',
    moreInfoText:
      'Please check that each individual has received an SMS/Email with an invitation to download the Credas app which we use to digitally verify your identification. This SMS/Email contains a unique code for each individual to use to login, once the app is downloaded.',
  },
  [ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL]: {
    component: HooYu,
    events: {
      individualStarted: ID_CHECK_HOOYU_STARTED_INDIVIDUAL,
      individualCompleted: ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL,
      completed: ID_CHECK_HOOYU,
    },
    thirdParty: 'HooYu',
    eventName: trackingEventsHumanName[ID_CHECK_HOOYU],
    splitPerClient: true,
    moreInfoHeading: 'ID/AML Check',
    moreInfoText:
      '<p>Please use the link button provided or check your inbox for emails from HooYu to access your digital ID/AML journey. You will need access to your photo ID and bank/utility documents showing your name and address to complete this process. Each person listed on the title must use their own link or email to complete this process.</p><p><strong>Note:</strong> The HooYu ID/AML check is used for Identity confirmation purposes only however it does leave an ID (Non Credit) search footprint on the consumers credit file. This will not adversely affect your credit score and is for information purposes only.</p>',
  },
};

export const splitPerClient = (clientIds, key, event) => {
  return clientIds.reduce((c, id) => ({ ...c, [`${key}_${id}`]: { ...event, clientId: id } }), {});
};

export const componentTrackedEvents = (clientIds, suppressed = []) => {
  return Object.entries(remortgageProgressTrackingEvents)
    .filter(([, v]) => !suppressed.includes(v.thirdParty))
    .filter(([, v]) => v.component)
    .reduce(
      (c, [k, v]) => ({
        ...c,
        ...(v.splitPerClient ? splitPerClient(clientIds, k, v) : { [k]: v }),
      }),
      {},
    );
};

export const otherTrackedEvents = (suppressed = []) =>
  Object.entries(remortgageProgressTrackingEvents)
    .filter(([, v]) => !suppressed.includes(v.thirdParty))
    .reduce((c, [k, v]) => ({ ...c, [k]: v }), {});
