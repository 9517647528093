<script>
export default {
  name: 'TermsOfBusiness',
  data() {
    return {
      termsAndConditionsPdf: '/Sort Move - TOB.pdf',
    };
  },
};
</script>

<template>
  <div>
    <h2>{{ $t('site.companyTitle') }} - Terms Of Business</h2>
    <div>
      <div id="page-content">
        <div class="border-bottom my-2">
          <a class="btn btn-info mb-2" :href="this.termsAndConditionsPdf" download="Sort Move - TOB">
            Download Terms of Business Here
          </a>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">1.These terms</h5>
          <ul>
            <li>
              1.1 <strong>What these terms cover</strong>:- these terms will set out the terms and conditions of how we
              propose to supply our services to you
            </li>
            <li>
              1.2 <strong>Why should you read them</strong>:- please read these terms carefully before you confirm your
              instruction confirmation. These terms will tell you who we are, what services we will provide to you, how
              and when we can change the clauses within this document, what to do if there is a problem and any other
              important information
            </li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">2. Information about us and how to contact us</h5>
          <ul>
            <li>
              2.1 <strong>Who we are</strong>:- We are {{ $t('site.companyTitle') }}, part of Sort Group Limited. Sort
              Group is registered at Burdsall House, Derby Conference Centre, London Road, Derby, DE24 8UX under Company
              Number 09907766. Our registered VAT number is 987 821 658
            </li>
            <li>
              2.2 <strong>How to contact us</strong>:- You can contact us by telephoning our customer services team at
              {{ $t('site.supportPhoneNumber') }} Or by email at {{ $t('site.supportEmail') }} or in writing to
              {{ $t('site.companyTitle') }}, Burdsall House, Derby Conference Centre, London Road, Derby, DE24 8UX
            </li>
            <li>
              2.3 <strong>How we may contact you</strong>:- If we need to contact you we will do so by telephone or by
              writing to you by email or post using the information provided to us by your estate agent.
            </li>
            <li>
              2.4 <strong>Lead name</strong>:- The person making the instruction is classed as the “lead name” in the
              instruction. If you are the lead named person on the instruction you confirm that you have authority to
              accept these terms and conditions on behalf of all parties to the transaction. We will contact you for all
              information in relation thereto.
            </li>
            <li>
              2.5 <strong>“Writing” includes emails</strong>:- When using the words “writing” or “written” in this
              document you can assume that the term also includes by email
            </li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">3. Our Contract with you:</h5>
          <ul>
            <li>
              3.1 <strong>How we will accept your instruction</strong>:- We will accept your instruction when you have
              either ticked the box confirming that you have read and accepted these terms
            </li>

            <li>
              3.2 <strong>If we cannot accept your instruction</strong>:- If we are unable to accept your instruction,
              we will inform you of this as soon as is reasonably practicable and we will not charge you for our
              services. We will explain to you at the time the reason if we are unable to act. If we have paid out money
              on disbursements for you then this will be billed to you as a cost.
            </li>
            <li>
              3.3 <strong>We can only act in England and Wales</strong>:- Please note that due to differing laws we are
              only able to act on a property that is within the countries of England and Wales
            </li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">4. Your rights to make changes</h5>
          <ul>
            <li>
              If at any time you wish to make a change to the services that you have requested, then please contact us
              immediately. We will inform you as soon as practically possible if the request is possible. If it is
              possible, we will confirm any cost of the price of the services, the timing of the supply and anything
              else that may be relevant to your request. We will then ask your approval to proceed with the requested
              change. If we are unable to, or you are unhappy with any of the new terms then you may wish to end your
              contract with us. If so, please see – Your Rights to End the Contract- Clause
            </li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">5. Our rights to make changes/suspend services</h5>
          <ul>
            <li>5.1 <strong>We reserve the right to make changes in the following circumstances:</strong></li>

            <li>5.1.1 When there is a change in law or regulatory requirements; or</li>

            <li>
              5.1.2 When there is requirement to make technical adjustments or improvements, for example, to address a
              security threat.
            </li>

            <li>
              5.1.3 When there is a service disruption due to a third party being unable to carry out their duties that
              prohibits us obtaining the relevant information.
            </li>

            <li>5.1.4 If you do not pay our fee</li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">6. Providing the services</h5>
          <ul>
            <li>
              6.1 <strong>When we will provide the services</strong>:- Upon receiving a request from the Estate Agent we
              will contact you and provide all relevant information to you along documentation and confirmation of the
              next steps. We will start the process when you have provided the instructions as noted at 3.1. We will
              provide the service to you until the contract has been fulfilled by us or either you or we request to end
              the contract.
            </li>

            <li>
              6.2 <strong>We are not responsible for delays outside of our control</strong>:- If our supply of the
              services is delayed by an event outside of our control then we will contact you as soon as practically
              possible to let you know. We will take all possible steps to minimise the effect of any delay but note
              that we are not liable for delays caused by third parties or unforeseen events.
            </li>

            <li>
              6.3 <strong>Providing us with your required information</strong>:- To enable us to perform this service we
              will require certain amounts of information from you as quickly as you are able to provide it, we
              appreciate that obtaining this may take some time and we are happy to guide you through the process.
              Please note however, that you are responsible for the information that you provide and the more
              information that you provide from the beginning will allow us to provide you with a more efficient
              service. If you do not provide the information to us within a reasonable time of our request then we will
              not be held liable or responsible for any delay in the service to you
            </li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">7. Price and Payment</h5>
          <ul>
            <li>
              7.1 <strong>Our Costs</strong>:- The cost will depend on whether the tenure of the property is Freehold or
              Leasehold. The price that you are required to pay will be displayed within the Conveyancing fees quote
              presented by your agent and will be reconfirmed on our {{ $t('site.processName') }} portal prior to any
              work being carried out.
            </li>

            <li>
              7.2 <strong>Third party costs</strong>:- You will be required to pay for third party costs (known as
              disbursements) to us to enable us to provide the service, for example, property and land registry
              searches. These are included in the overall service cost for {{ $t('site.processName') }}.
            </li>

            <li>
              7.3 <strong>When and How to Pay</strong>:- When you agree to instructing our service, you will receive by
              email your unique login details. Once you have accepted these Terms of Business you will be invited to our
              secure online payment gateway. You can pay by debit or credit card.
            </li>

            <li>
              7.4 <strong>Interest on late payments</strong>:- We reserve the right to charge interest at 2% above the
              Bank of England base rate which will be accrued daily until payment has been received.
            </li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">8. Your rights to end the contract</h5>
          <ul>
            <li>
              8.1 <strong>You can end your contract with us</strong>:- The rights, timings and what happens when you
              cancel depends on the timings and reasons for ending the contract
            </li>

            <li>
              8.1.1 Within 14 days using the model cancellation form attached and returning it to us within 14 days of
              instructing us. This is called exercising your right to change your mind within the cooling off period
              under the Consumer Contracts Regulations 2013.
            </li>

            <li>
              8.1.2 If you have changed your mind outside of the 14 day cooling off period then you can still cancel the
              contract, however, any completed or requested services on your behalf remains payable.
            </li>

            <li>
              8.1.3 If we haven’t spent any money on your behalf, you will be refunded in full. Any money spent will be
              accounted to you for. All documentation ordered on your behalf once you have paid the fee is owned by you
              and will be sent to you upon receipt of your cancellation so long as you do not owe us any further funds
              for the service.
            </li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">9. Our right to end the contract</h5>
          <ul>
            <li>
              9.1 <strong>We are also entitled to end the contract</strong>:- If you break the contract for service then
              we can also end the contract at any time by writing to you if:-
            </li>

            <li>
              9.1.1 You do not make payment to us when it is due and you still do not make payment within 14 days of us
              reminding you; or
            </li>

            <li>
              9.1.2 You do not, within a reasonable time of us requesting it provide the necessary information for us to
              provide the service, or
            </li>

            <li>9.1.3 Your appointed estate agent is no longer able to market your property, or</li>

            <li>
              9.1.4 Our money laundering checks are not passed for any reason and cannot be resolved to continue to
              provide our services.
            </li>

            <li>
              9.2 <strong>We may withdraw our offer of services</strong>:- If we decide to no longer offer this service
              then we will write to you at least 7 days in advance of us stopping this service and refund any sums paid
              in advance to which we have not been able to complete.
            </li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">10. If there is a problem with the service</h5>
          <ul>
            <li>
              10.1 <strong>How to tell us about problems</strong>:- If you have any questions or concerns in relation to
              our services please contact us. You can telephone our customer services team on
              {{ $t('site.supportPhoneNumber') }} or email us at {{ $t('site.supportEmail') }} or write to us at
              {{ $t('site.companyTitle') }}, Burdsall House, Derby Conference Centre, London Road, Derby, DE24 8UX
            </li>

            <li>
              10.2 <strong>Summary of our key legal rights</strong>:- This is a summary of your key legal rights and are
              subject to certain exemptions, if you have any questions we suggest that you visit the Citizens Advice
              Bureau website www.adviceguide.org.uk or call them on 03454 04 05 06
            </li>

            <li>10.2.1 The Consumer Rights Act says:</li>

            <li>
              10.2.1.1 You can ask us to repeat or fix a service if it’s not carried out with reasonable care and skill
              or get some money back if we can’t fix it
            </li>

            <li>10.2.1.2 If you haven’t agreed a price beforehand, what you are asked to pay must be reasonable</li>

            <li>
              10.2.1.3 If you haven’t agreed a timescale beforehand, that it must be carried out within a reasonable
              time.
            </li>
          </ul>
        </div>

        <div class="border-bottom my-2">
          <h5 class="mb-1">11. How we may use your personal information</h5>
          <ul>
            <li>
              11.1 We only use your personal information as set out in our privacy policy which can be found here:
              {{ $t('site.privacyPolicyUrl') }}
            </li>
          </ul>
        </div>

        <h3>See overleaf for our Model Cancellation Form</h3>

        <div>
          <h5 class="mb-1">MODEL CANCELLATION FORM</h5>
          <p>Complete and return this form only if you wish to withdraw from the contract)</p>
          <ul>
            <li>To: {{ $t('site.companyTitle') }}</li>
            <li>Burdsall House</li>
            <li>Derby Conference Centre</li>
            <li>London Road</li>
            <li>Derby</li>
            <li>DE24 8UX</li>
            <li class="pb-2"></li>
            <li>
              I/We* hereby give notice that I/We* cancel my/our* contract of sale for the supply of the following
              service*,
            </li>
            <li class="pb-2"></li>
            <li>Ordered on*</li>
            <li class="pb-2"></li>
            <li>Name of consumer(s),</li>
            <li>Address of consumer(s),</li>
            <li>Signature of consumer(s) (only if this form is notified on paper),</li>
            <li>Date</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
}

.modal-container {
  width: 100% !important;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}

ul {
  list-style: none;
}
</style>
