var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loader',{staticClass:"pb-2"},[(_vm.showDelayInfo)?_c('span',[_vm._v(_vm._s(_vm.showDelayInfoText))]):_vm._e()]):_c('section',[_c('div',[_vm._l((this.trackedEvents),function(event,k){return _c(event.component,_vm._b({key:k,tag:"component",on:{"reload":function (e) { return _vm.setUpdateDetector(e); },"show-more-info-modal":function (e) { return _vm.showMoreInfoModal(e); }}},'component',Object.assign({}, event,
        {client: _vm.clientsById[event.clientId],
        documents: _vm.documents,
        entityId: _vm.entityId,
        entityType: _vm.entityType,
        existingTrackingEvents: _vm.existingTrackingEvents,
        eventKey: k,
        taskStatus: _vm.taskStatus,
        supplierInformationName: _vm.supplierInformationName}),false))}),(_vm.isModalOpen)?_c('more-info-modal',{attrs:{"bodyText":_vm.modalText,"headingText":_vm.modalHeading},on:{"close":_vm.hideMoreInfoModal}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }