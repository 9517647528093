import { TERMS_OF_BUSINESS_CONFIRMED } from '@/values/trackingEvents';

export default {
  name: 'Terms Of Business',
  icon: 'TOB',
  event_slug: 'terms_of_business',
  trackingEvents: {
    completedEvent: TERMS_OF_BUSINESS_CONFIRMED,
  },
  visible: true,
  active: false,
  status: false,
  whatsNextInfo: false,
};
