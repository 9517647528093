import {
  CLIENT_QUESTIONNAIRE_COMPLETED,
  CLIENT_QUESTIONNAIRE_STARTED,
  CLIENT_CARE_PACK_SIGNED_INDIVIDUAL,
  CLIENT_CARE_PACK_SIGNED,
  PAYMENT_COMPLETED,
  TERMS_OF_BUSINESS_CONFIRMED,
  CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL,
  PURCHASE_QUESTIONNAIRE_SIGNING_COMPLETE,
  ID_CHECK_HOOYU,
  ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL,
  ID_CHECK_HOOYU_STARTED_INDIVIDUAL,
} from '@/values/trackingEvents';
import HooYu from '../components/trackedEvents/HooYu.vue';
import Yoti from '../components/trackedEvents/Yoti.vue';
import trackingEventsHumanName from './trackingEventsHumanName';

export default {
  paymentCompleted: PAYMENT_COMPLETED,
  clientQuestionnaireStarted: CLIENT_QUESTIONNAIRE_STARTED,
  clientQuestionnaireCompleted: CLIENT_QUESTIONNAIRE_COMPLETED,
  termsOfBusinessConfirmed: TERMS_OF_BUSINESS_CONFIRMED,
  clientQuestionnaireSignedIndividual: CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL,
};

// This defines the steps shown under "Sale Progress"
export const clientQuestionnaireProgressTrackingEvents = {
  [CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL]: {
    component: Yoti,
    splitPerClient: true,
    yotiSign: true,
    eventName: trackingEventsHumanName[CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL],
    events: {
      individualCompleted: CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL,
      completed: PURCHASE_QUESTIONNAIRE_SIGNING_COMPLETE,
    },
    thirdParty: 'Yoti',
    documentName: 'Client Questionnaire',
    documentType: 'CLIENT_QUESTIONNAIRE',
    moreInfoHeading: 'Sign Client Questionnaire',
    moreInfoText:
      'Please check your inbox for emails from YotiSign with attached copies of your Client Questionnaire. Please follow the simple process to digitally sign these documents. Each person listed on the title deed must complete this process.',
    moreInfoAlternativeText:
      'Please use the link button provided or check your inbox for emails from YotiSign to access copies of your Client Questionnaire. Follow the simple process to securely digitally sign this document. Each person listed on the title must use their own link or email to complete this process.',
  },
  [CLIENT_CARE_PACK_SIGNED_INDIVIDUAL]: {
    component: Yoti,
    splitPerClient: true,
    yotiSign: true,
    eventName: trackingEventsHumanName[CLIENT_CARE_PACK_SIGNED_INDIVIDUAL],
    events: {
      individualCompleted: CLIENT_CARE_PACK_SIGNED_INDIVIDUAL,
      completed: CLIENT_CARE_PACK_SIGNED,
    },

    thirdParty: 'Yoti',
    documentName: 'Client Care Pack',
    documentType: 'CLIENT_CARE_PACK',
    moreInfoHeading: 'Sign Client Care Pack',
    moreInfoText:
      'Please check your inbox for emails from YotiSign with attached copies of your Client Care Pack. Please follow the simple process to digitally sign these documents. Each person listed on the title deed must complete this process.',
    moreInfoAlternativeText:
      'Please use the link button provided or check your inbox for emails from YotiSign to access copies of your Client Care Pack. Follow the simple process to securely digitally sign this document. Each person listed on the title must use their own link or email to complete this process.',
  },
  [ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL]: {
    component: HooYu,
    events: {
      individualStarted: ID_CHECK_HOOYU_STARTED_INDIVIDUAL,
      individualCompleted: ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL,
      completed: ID_CHECK_HOOYU,
    },
    thirdParty: 'HooYu',
    eventName: trackingEventsHumanName[ID_CHECK_HOOYU],
    splitPerClient: true,
    moreInfoHeading: 'ID/AML Check',
    moreInfoText:
      '<p>Please use the link button provided or check your inbox for emails from HooYu to access your digital ID/AML journey. You will need access to your photo ID and bank/utility documents showing your name and address to complete this process. Each person listed on the title must use their own link or email to complete this process.</p><p><strong>Note:</strong> The HooYu ID/AML check is used for Identity confirmation purposes only however it does leave an ID (Non Credit) search footprint on the consumers credit file. This will not adversely affect your credit score and is for information purposes only.</p>',
  },
};

export const splitPerClient = (clientIds, key, event) => {
  return clientIds.reduce((c, id) => ({ ...c, [`${key}_${id}`]: { ...event, clientId: id } }), {});
};

// Support HooYoo and Embedded Yoti
export const componentTrackedEvents = (
  clientIds,
  suppressed = [],
  progressTrackingEvents,
  suppressedDocumentTypes = [],
) => {
  return Object.entries(progressTrackingEvents)
    .filter(([, v]) => !suppressed.includes(v.thirdParty))
    .filter(([, v]) => !suppressedDocumentTypes.includes(v.documentType))
    .filter(([, v]) => v.component)
    .reduce(
      (c, [k, v]) => ({
        ...c,
        ...(v.splitPerClient ? splitPerClient(clientIds, k, v) : { [k]: v }),
      }),
      {},
    );
};

// This method return old cases tracking events for 'Credas' and not embedded 'Yoti'
export const otherTrackedEvents = (suppressed = [], progressTrackingEvents, suppressedDocumentTypes = []) =>
  Object.entries(progressTrackingEvents)
    .filter(([, v]) => !suppressed.includes(v.thirdParty))
    .filter(([, v]) => !suppressedDocumentTypes.includes(v.documentType))
    .reduce((c, [k, v]) => ({ ...c, [k]: v }), {});
