<script>
import getTrackingEvents from '@/api/trackingEvents/getTrackingEvents';
import getDocuments from '@/api/documentManager/getDocuments';
import {
  componentTrackedEvents,
  otherTrackedEvents,
  remortgageProgressTrackingEvents,
} from '@/values/remortgageTrackingEvents';
import trackingEventsHumanName from '@/values/trackingEventsHumanName';
import getPerson from '@/api/persons/getPerson';
import MoreInfoModal from '@/components/messaging/MoreInfoModal';
import { mapActions } from 'vuex';
import { ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL } from '@/values/trackingEvents';
import Loader from './Loader.vue';

export default {
  components: { Loader, MoreInfoModal },
  name: 'RemortgageProgress',
  data() {
    return {
      checkingYotiStatus: [],
      clients: [],
      clientsById: {},
      delayLoading: this.delayLoad,
      docs: [],
      documents: [],
      existingTrackingEvents: [],
      isModalOpen: false,
      loading: true,
      modalHeading: '',
      modalText: '',
      showDelayInfo: false,
      timerLoop: 0,
      trackedEvents: {},
      trackingEventsToDisplay: {},
    };
  },
  props: {
    entityId: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    clientIds: {
      type: Array,
    },
    moreInfoLinksVisible: {
      type: Boolean,
      required: true,
    },
    delayLoad: {
      type: Boolean,
      required: false,
    },
    eventToHandle: {
      type: String,
    },
    locked: {
      type: Boolean,
      required: false,
    },
    suppressedServices: {
      type: Array,
    },
    taskStatus: {
      type: String,
    },
  },
  methods: {
    componentTrackedEvents: componentTrackedEvents,
    ...mapActions('remortgage', ['setEventToHandle']),
    renderHumanName(trackingEventToDisplay) {
      const eventName = trackingEventsHumanName[trackingEventToDisplay.eventName];
      return trackingEventToDisplay.splitPerClient
        ? `${eventName} ${trackingEventToDisplay.clientFullName}`
        : eventName;
    },
    async loadProgressTasks() {
      this.loading = true;
      // Load clients - we need this to display individual tracking events
      this.clients = (await Promise.all(this.clientIds.map((id) => getPerson(id)))).map(({ data }) => data);
      this.clientsById = this.clients.reduce((c, client) => ({ ...c, [client.Id]: client }), {});

      this.documents = await this.loadDocuments(this.entityId);

      // Create an object of events and statuses from the available events
      Object.entries(otherTrackedEvents(this.suppressedServices)).forEach(([key, value]) => {
        if (value.component) {
          return;
        }
        if (value.splitPerClient) {
          this.clients.forEach((client) => {
            const eventKey = `${client.Id}_${key}`;
            this.trackingEventsToDisplay[eventKey] = {
              completed: false,
              eventName: key,
              splitPerClient: value.splitPerClient,
              documentName: value.documentName,
              documentType: value.documentType,
              clientId: client.Id,
              clientEmail: client.email,
              clientFullName: `${client.first_name} ${client.last_name}`,
            };
          });
        } else {
          this.trackingEventsToDisplay[key] = {
            completed: false,
            eventName: key,
          };
        }
      });

      await this.loadAndMatchTrackingEvents();

      this.loading = false;
    },
    async loadDocuments(entityId) {
      return await getDocuments(entityId)
        .then((response) => response.data.results)
        .catch((err) => console.error(err));
    },
    async loadAndMatchTrackingEvents() {
      // Fetch progress and set statuses as necessary
      const trackingEventNames = Object.entries(remortgageProgressTrackingEvents).reduce(
        (c, [event, { events = {} }]) => [...c, event, ...Object.values(events)],
        [],
      );
      this.existingTrackingEvents = await getTrackingEvents(this.entityId, trackingEventNames)
        .then((response) => response.data.results)
        .catch((err) => console.error(err));
    },
    showMoreInfoModal(eventDisplayInfo) {
      const eventName = eventDisplayInfo.eventName;
      let moreInfoText = remortgageProgressTrackingEvents[eventName].moreInfoText;
      let moreInfoAlternativeText = '';

      if (
        eventDisplayInfo?.displayAlternativeText &&
        remortgageProgressTrackingEvents[eventName]?.moreInfoAlternativeText
      ) {
        moreInfoAlternativeText = remortgageProgressTrackingEvents[eventName]?.moreInfoAlternativeText;
      }

      this.modalHeading = remortgageProgressTrackingEvents[eventName].moreInfoHeading;
      this.modalText = eventDisplayInfo?.displayAlternativeText ? moreInfoAlternativeText : moreInfoText;
      this.isModalOpen = true;
    },
    hideMoreInfoModal() {
      this.isModalOpen = false;
    },
    setUpdateDetector(key) {
      if (this.trackedEvents.hasOwnProperty(key)) {
        this.trackedEvents[key].parentLoading = true;
        // To check latest progress changes each 5sec upto 10 times
        const timer = setInterval(() => {
          this.timerLoop++;
          if (this.timerLoop > 9) {
            this.timerLoop = 0;
            clearInterval(timer);
            this.trackedEvents[key].parentLoading = false;
          }
          this.loadAndMatchTrackingEvents();
        }, 3000);
      }
    },
  },
  async mounted() {
    // this.docs = (await getDocuments(this.entityId)).data.results;
    this.trackedEvents = componentTrackedEvents(this.clientIds, this.suppressedServices);

    if (this.eventToHandle && this.eventToHandle.includes('requested-key:')) {
      const key = this.eventToHandle.replace('requested-key:', '');

      if (key && this.trackedEvents.hasOwnProperty(key)) {
        this.setUpdateDetector(key);
        this.delayLoading = false;
      }
    }

    if (this.$route.query?.reference && this.$route.query?.person) {
      this.setUpdateDetector(`${ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL}_${this.$route.query.person}`);
      this.delayLoading = false;
    }

    if (this.delayLoading) {
      // We are delay loading progress task as we wait for document creation
      // Client care pack and questionnaire as we need documents with yoti tokens
      this.showDelayInfo = true;
      await setTimeout(() => {
        this.showDelayInfo = false;
        this.loadProgressTasks();
      }, 20000);
    } else {
      await this.loadProgressTasks();
    }

    this.setEventToHandle(null);
  },
};
</script>

<template>
  <loader v-if="loading" class="pb-2"><span v-if="showDelayInfo">Please wait, creating list of tasks...</span></loader>
  <section v-else>
    <div>
      <div
        v-for="(trackingEventToDisplay, key) in trackingEventsToDisplay"
        v-bind:key="key"
        class="row d-flex align-items-center border-top p-2 bg-light"
      >
        <!-- Name -->
        <div class="col-sm-12 col-md-6">
          <div class="d-flex justify-content-center justify-content-md-start">
            <h4 class="text-default font-weight-semibold letter-icon-title">
              <span class="pl-3">-</span>
              {{ renderHumanName(trackingEventToDisplay) }}
            </h4>
          </div>
        </div>
        <!-- /end name -->

        <!-- Status -->
        <div class="col-sm-12 col-md-1">
          <div class="d-flex justify-content-center justify-content-md-start">
            <h4 class="status">
              <span
                :class="`${
                  trackingEventToDisplay.completed ? 'badge-success' : 'badge-danger'
                } badge badge-pill border-3 mr-1 mb-1 text-uppercase`"
                >{{ trackingEventToDisplay.completed ? 'Complete' : 'Incomplete' }}</span
              >
            </h4>
          </div>
        </div>
        <!-- /end status -->

        <!-- More Info modal link -->
        <div class="col-sm-12 col-md-2">
          <h4
            v-if="moreInfoLinksVisible && !trackingEventToDisplay.completed"
            class="d-flex justify-content-center justify-content-md-start"
          >
            <span
              class="cursor-pointer text-info d-inline text-nowrap ml-md-3"
              @click="showMoreInfoModal(trackingEventToDisplay)"
            >
              <i class="icon-info22 mr-2 icon"></i>
              <span>More info</span>
            </span>
          </h4>
        </div>
        <!-- /end More Info modal link -->
      </div>
      <component
        v-bind:is="event.component"
        v-for="(event, k) in this.trackedEvents"
        v-bind:key="k"
        v-bind="{
          ...event,
          client: clientsById[event.clientId],
          documents,
          entityId,
          entityType,
          existingTrackingEvents,
          eventKey: k,
          locked,
          taskStatus,
        }"
        v-on:reload="(e) => setUpdateDetector(e)"
        v-on:show-more-info-modal="(e) => showMoreInfoModal(e)"
      ></component>
      <!-- More Info modal -->
      <more-info-modal v-if="isModalOpen" :bodyText="modalText" :headingText="modalHeading" @close="hideMoreInfoModal">
      </more-info-modal>
      <!-- /end More Info modal -->
    </div>
  </section>
</template>
<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  h3,
  h4,
  span {
    margin: 0;
    padding: 5px;
  }
}
</style>
