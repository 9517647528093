<script>
import { mapMutations } from 'vuex';
import * as saleMutations from '@/store/sale/mutations';
import * as purchaseMutations from '@/store/purchase/mutations';
import * as remortgageMutations from '@/store/remortgage/mutations';
import getTrackingEvents from '@/api/trackingEvents/getTrackingEvents';
import getDocuments from '@/api/documentManager/getDocuments';
import getPerson from '@/api/persons/getPerson';
import MoreInfoModal from '@/components/messaging/MoreInfoModal';
import { ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL } from '@/values/trackingEvents';
import Loader from './Loader.vue';

const showTaskProgressLoaderText = [
  {
    text: 'Submitting your form...',
    timeout: 5000,
  },
  {
    text: 'Generating your documents...',
    timeout: 5000,
  },
  {
    text: 'Creating e-sign request...',
    timeout: 5000,
  },
  {
    text: 'Please wait, creating list of tasks...',
    timeout: 5000,
  },
];

export default {
  components: { Loader, MoreInfoModal },
  name: 'TaskProgress',
  data() {
    return {
      loading: true,
      delayLoading: this.sectionEventSlug === this.sectionDelayLoad && this.sectionEventSlug !== '',
      existingTrackingEvents: [],
      trackingEventsToDisplay: {},
      trackedEvents: {},
      clients: [],
      clientsById: {},
      documents: [],
      docs: [],
      isModalOpen: false,
      modalHeading: '',
      modalText: '',
      timerLoop: 0,
      checkingYotiStatus: [],
      showDelayInfo: false,
      showDelayInfoText: '',
      showTaskProgressLoaderText,
    };
  },
  props: {
    taskStatus: {
      type: String,
    },
    entityId: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    clientIds: {
      type: Array,
    },
    moreInfoLinksVisible: {
      type: Boolean,
      required: true,
    },
    eventToHandle: {
      type: String,
    },
    sectionDelayLoad: {
      type: String,
      required: true,
    },
    sectionEventSlug: {
      type: String,
      required: true,
    },
    suppressedServices: {
      type: Array,
    },
    componentTrackedEvents: {
      type: Object,
      required: true,
    },
    otherTrackedEvents: {
      type: Object,
      required: true,
    },
    progressTrackingEvents: {
      type: Object,
      required: true,
    },
    supplierInformationName: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  methods: {
    ...mapMutations([
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_EVENT_TO_HANDLE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_EVENT_TO_HANDLE}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EVENT_TO_HANDLE}`,
    ]),
    async currentDelayTaskToDisplay() {
      for (let i = 0; i < this.showTaskProgressLoaderText.length; i++) {
        let currentStep = this.showTaskProgressLoaderText[i];
        this.showDelayInfoText = currentStep.text;
        await new Promise((resolve) => setTimeout(resolve, currentStep.timeout));
      }

      this.showDelayInfo = false;
      await this.loadProgressTasks();
    },
    async loadProgressTasks() {
      this.loading = true;
      // Load clients - required to display individual tracking events per each client
      this.clients = (await Promise.all(this.clientIds.map((id) => getPerson(id)))).map(({ data }) => data);
      // Client by id will be required to get match with individual HooYu/Credas metadata: PersonId
      this.clientsById = this.clients.reduce((c, client) => ({ ...c, [client.Id]: client }), {});
      // Load documents - will be used to check if document to sign own envelope id what allows for embedded sign
      this.documents = await this.loadDocuments(this.entityId);

      // Create an object of progress events and their statuses from the available progress tasks events
      // Bellow code works only for Credas and old cases, code will be skipped for HooYu and Embedded Yoti
      Object.entries(this.otherTrackedEvents).forEach(([key, value]) => {
        if (value.component) {
          return;
        }

        if (value.splitPerClient) {
          this.clients.forEach((client) => {
            const eventKey = `${client.Id}_${key}`;
            this.trackingEventsToDisplay[eventKey] = {
              completed: false,
              eventName: key,
              splitPerClient: value.splitPerClient,
              documentName: value.documentName,
              documentType: value.documentType,
              clientId: client.Id,
              clientEmail: client.email,
              clientFullName: `${client.first_name} ${client.last_name}`,
            };
          });
        } else {
          this.trackingEventsToDisplay[key] = {
            completed: false,
            eventName: key,
          };
        }
      });

      // Load HooYu and Embedded Yoti progress tasks
      await this.loadAndMatchTrackingEvents();

      this.loading = false;
    },
    async loadDocuments(entityId) {
      return await getDocuments(entityId)
        .then((response) => response.data.results)
        .catch((err) => console.error(err));
    },
    async loadAndMatchTrackingEvents() {
      // Tracking events names for HooYu and Embedded Yoti
      const trackingEventNames = Object.entries(this.progressTrackingEvents).reduce(
        (c, [event, { events = {} }]) => [...c, event, ...Object.values(events)],
        [],
      );
      // Fetch existing tracking events for HooYu and Embedded Yoti
      this.existingTrackingEvents = await getTrackingEvents(this.entityId, trackingEventNames)
        .then((response) => response.data.results)
        .catch((err) => console.error(err));
    },
    showMoreInfoModal(eventDisplayInfo) {
      const eventName = eventDisplayInfo.eventName;
      let moreInfoText = this.progressTrackingEvents[eventName].moreInfoText;
      let moreInfoAlternativeText = '';

      if (eventDisplayInfo?.displayAlternativeText && this.progressTrackingEvents[eventName]?.moreInfoAlternativeText) {
        moreInfoAlternativeText = this.progressTrackingEvents[eventName]?.moreInfoAlternativeText;
      }

      this.modalHeading = this.progressTrackingEvents[eventName].moreInfoHeading;
      this.modalText = eventDisplayInfo?.displayAlternativeText ? moreInfoAlternativeText : moreInfoText;
      this.isModalOpen = true;
    },
    hideMoreInfoModal() {
      this.isModalOpen = false;
    },
    setUpdateDetector(key) {
      if (this.trackedEvents.hasOwnProperty(key)) {
        this.trackedEvents[key].parentLoading = true;
        // To check latest progress changes each 5sec upto 10 times
        const timer = setInterval(() => {
          this.timerLoop++;
          if (this.timerLoop > 9) {
            this.timerLoop = 0;
            clearInterval(timer);
            this.trackedEvents[key].parentLoading = false;
          }
          this.loadAndMatchTrackingEvents();
        }, 3000);
      }
    },
  },
  async mounted() {
    // this.docs = (await getDocuments(this.entityId)).data.results;
    this.trackedEvents = this.componentTrackedEvents;

    // Handle signing request and set loader on progress task button
    if (this.eventToHandle && this.eventToHandle.includes('requested-key:')) {
      const key = this.eventToHandle.replace('requested-key:', '');

      if (key && this.trackedEvents.hasOwnProperty(key)) {
        // Loader will be removed after fixed time or when new individual signing request will be fetched
        this.setUpdateDetector(key);
        this.delayLoading = false;
      }
    }

    if (this.$route.query?.reference && this.$route.query?.person) {
      this.setUpdateDetector(`${ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL}_${this.$route.query.person}`);
      this.delayLoading = false;
    }

    if (this.delayLoading) {
      // We are delay loading progress task as we wait for document creation
      // Client care pack and questionnaire as we need documents with yoti tokens
      this.showDelayInfo = true;
      await this.currentDelayTaskToDisplay();
    } else {
      await this.loadProgressTasks();
    }

    // Set event to handle to null as we made all required actions after task submission
    if (this.entityType === 'sale') {
      this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_EVENT_TO_HANDLE}`](null);
    } else if (this.entityType === 'purchase') {
      this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_EVENT_TO_HANDLE}`](null);
    } else if (this.entityType === 'remortgage') {
      this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EVENT_TO_HANDLE}`](null);
    }
  },
};
</script>

<template>
  <loader v-if="loading" class="pb-2">
    <span v-if="showDelayInfo">{{ showDelayInfoText }}</span>
  </loader>
  <section v-else>
    <div>
      <component
        v-bind:is="event.component"
        v-for="(event, k) in this.trackedEvents"
        v-bind:key="k"
        v-bind="{
          ...event,
          client: clientsById[event.clientId],
          documents,
          entityId,
          entityType,
          existingTrackingEvents,
          eventKey: k,
          taskStatus,
          supplierInformationName,
        }"
        v-on:reload="(e) => setUpdateDetector(e)"
        v-on:show-more-info-modal="(e) => showMoreInfoModal(e)"
      ></component>
      <!-- More Info modal -->
      <more-info-modal v-if="isModalOpen" :bodyText="modalText" :headingText="modalHeading" @close="hideMoreInfoModal">
      </more-info-modal>
      <!-- /end More Info modal -->
    </div>
  </section>
</template>
<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  h3,
  h4,
  span {
    margin: 0;
    padding: 5px;
  }
}
</style>
