import {
  CLIENT_QUESTIONNAIRE_COMPLETED,
  PAYMENT_COMPLETED,
  TERMS_OF_BUSINESS_CONFIRMED,
} from '@/values/trackingEvents';

export default (trackingEvents, tasks) => {
  let filteredEvents = [];
  trackingEvents.forEach((trackingEvent) => {
    filteredEvents.push(
      typeof trackingEvent.EventSlug === 'undefined' ? trackingEvent.event_slug : trackingEvent.EventSlug,
    );
  });

  // If terms of business are not required we need to unlock further tasks
  let termsOfBusinessRequired = tasks.find((element) => element.event_slug === 'terms_of_business') !== undefined;

  // If payment is not required we need to unlock further tasks
  let paymentEvent = tasks.find((element) => element.path === 'Payments');
  let paymentRequired = paymentEvent && paymentEvent.visible;

  // Each task lookup for specific tracking events to be not started, in progress or completed.
  let paymentCompleted = filteredEvents.includes(PAYMENT_COMPLETED);
  let clientQuestionnaireCompleted = filteredEvents.includes(CLIENT_QUESTIONNAIRE_COMPLETED);
  let termsOfBusinessConfirmed = filteredEvents.includes(TERMS_OF_BUSINESS_CONFIRMED);

  tasks.forEach(function (task) {
    if (task.status == 'additional') {
      if (clientQuestionnaireCompleted) {
        task.active = true;
      } else {
        task.active = false;
      }
    } else if (filteredEvents.includes(task.trackingEvents.completedEvent)) {
      task.status = 'completed';
      task.active = true;
    } else if (
      'furtherInputNeededEvent' in task.trackingEvents &&
      filteredEvents.includes(task.trackingEvents.furtherInputNeededEvent)
    ) {
      task.status = 'actionRequired';
      task.active = true;
    } else if ('pendingEvent' in task.trackingEvents && filteredEvents.includes(task.trackingEvents.pendingEvent)) {
      task.status = 'processing';
      task.active = true;
    } else if (
      filteredEvents.includes(task.trackingEvents.startedEvent) &&
      task.trackingEvents.startedEvent !== false
    ) {
      task.status = task.status === 'available' ? task.status : 'inProgress';
      task.active = true;
    } else if (termsOfBusinessRequired && !termsOfBusinessConfirmed && task.event_slug !== 'terms_of_business') {
      task.status = 'termsOfBusinessConfirmation';
      task.active = false;
    } else if (
      !paymentCompleted &&
      paymentRequired &&
      task.event_slug !== 'fast_start_pack_payment' &&
      task.event_slug !== 'terms_of_business'
    ) {
      task.status = 'paymentRequired';
      task.active = false;
    } else if (
      !clientQuestionnaireCompleted &&
      (!paymentRequired || paymentCompleted) &&
      task.event_slug !== 'fast_start_pack_payment' &&
      task.event_slug !== 'client_questionnaire' &&
      task.event_slug !== 'terms_of_business'
    ) {
      task.status = 'completeClientQuestionnaire';
      task.active = false;
    } else {
      task.status = 'notStarted';
      task.active = true;
    }
  });

  return tasks;
};
