<script>
export default {
  name: 'CompletedRemortgageQuestionnaire',
  props: {
    config: {
      type: Object,
      required: false,
    },
  },
  methods: {
    serviceSuppressed(service) {
      if (this.config === null) {
        return false;
      }

      return this.config?.suppress_services?.indexOf(service) > -1; // Means exist in array
    },
    printCurrentStep() {
      const currentValue = this.currentStep;
      this.currentStep += 1;
      return currentValue;
    },
  },
  data() {
    return {
      currentStep: 1,
      checklistImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('dashboard.checklistIcon')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundSize: '10rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '10rem',
      },
      logoImg: {
        backgroundImage: `url(${require(`@/assets/${this.$i18n.t('site.logo')}`)})`, // eslint-disable-line import/no-dynamic-require
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '5rem',
      },
      sections: {
        Credas: 0,
        HooYu: 0,
        Yoti: 0,
      },
    };
  },
  mounted() {
    let i = 1;
    Object.keys(this.sections).forEach((k) => {
      this.sections[k] = this.serviceSuppressed(k) ? 0 : i++;
    });
  },
};
</script>

<template>
  <div id="page-content">
    <h2>Client questionnaire completed</h2>

    <div class="my-2" :style="checklistImg"></div>

    <p class="my-2">
      <strong>Thank you for completing your Client Questionnaire!</strong>
    </p>

    <p class="my-2 heading-color">
      <strong>Here is what you need to complete next:</strong>
    </p>

    <section v-if="!serviceSuppressed('Credas')">
      <div class="row col-12">
        <div class="col-12 col-sm-2 mb-2 mb-sm-0">
          <button class="btn-round border-2" type="button">
            {{ this.sections.Credas }}
          </button>
        </div>
        <div class="col-12 col-sm-10">
          <p>
            <strong>Verify your ID – through Credas -</strong> you have been sent an SMS/Email with an invite to
            download the 'Credas' app to verify your identification. Each person listed on the title deed must complete
            this process.
          </p>
          <p>
            <strong>Note:</strong> If you would like to understand more about Credas and the process, please click to
            watch our Credas ID guide
          </p>

          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            frameborder="0"
            height="360"
            src="https://www.youtube.com/embed/zWBlabWBhFk"
            width="100%"
          ></iframe>
          <p class="d-block d-sm-block d-md-none">
            <a href="https://www.youtube.com/watch?v=zWBlabWBhFk">Watch Credas Video Guide</a>
          </p>
        </div>
      </div>
    </section>

    <section v-if="!serviceSuppressed('HooYu')">
      <div class="row col-12">
        <div class="col-12 col-sm-2 mb-2 mb-sm-0">
          <button class="btn-round border-2" type="button">
            {{ this.sections.HooYu }}
          </button>
        </div>
        <div class="col-12 col-sm-10">
          <p>
            <strong>Verify your ID – through HooYu - </strong>Please use the link button provided or check your inbox
            for emails from HooYu to access your digital ID/AML journey. You will need access to your photo ID and
            bank/utility documents showing your name and address to complete this process. Each person listed on the
            title must use their own link or email to complete this process.
          </p>
          <p>
            <strong>Note:</strong> The HooYu ID/AML check is used for Identity confirmation purposes only however it
            does leave an ID (Non Credit) search footprint on the consumers credit file. This will not adversely affect
            your credit score and is for information purposes only.
          </p>
        </div>
      </div>
    </section>

    <section v-if="!serviceSuppressed('Yoti')">
      <div class="row col-12 mt-3">
        <div class="col-12 col-sm-2 mb-2 mb-sm-0">
          <button class="btn-round border-2" type="button">
            {{ this.sections.Yoti }}
          </button>
        </div>
        <div class="col-12 col-sm-10">
          <strong>Digitally sign your documents through YotiSign –</strong>
          Please check your email inbox (including Spam/Junk folders) for an invite from YotiSign with attached copies
          of your Client Questionnaire and Client Care Pack. Please follow the simple process to digitally sign these
          documents. Each person listed on the title deed will receive an invitation to sign and must individually
          complete this process.
        </div>
      </div>
    </section>

    <div class="mt-3" :style="logoImg"></div>
  </div>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
  text-align: center;
}

.modal-container {
  width: 100% !important;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}

.btn-round {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 35px;
  line-height: 1.33;
  pointer-events: none;
  background-color: white;
}
</style>
