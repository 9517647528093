import { CLIENT_QUESTIONNAIRE_COMPLETED, CLIENT_QUESTIONNAIRE_STARTED } from '@/values/trackingEvents';

export default {
  name: 'Client Questionnaire',
  path: 'PurchaseClientQuestionnaire',
  icon: 'CQ',
  event_slug: 'client_questionnaire',
  trackingEvents: {
    startedEvent: CLIENT_QUESTIONNAIRE_STARTED,
    completedEvent: CLIENT_QUESTIONNAIRE_COMPLETED,
  },
  visible: true,
  active: false,
  status: false,
  whatsNextInfo: false,
};
