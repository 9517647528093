import PostTrackingEvents from '@/api/trackingEvents/postTrackingEvents';

export default (productSlug, entityId, callback) => {
  let trackingEvent = {
    product_slug: productSlug,
    entity_id: entityId,
    event_slug: 'terms_of_business_confirmed',
    metadata: '',
  };
  PostTrackingEvents(trackingEvent).catch((err) => console.error(err));

  if (typeof callback === 'function') {
    callback(trackingEvent);
  }
};
