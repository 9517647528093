import { TA10_COMPLETED, TA10_STARTED } from '@/values/trackingEvents';

export default {
  name: 'Fittings and Contents',
  path: 'Ta10Form',
  icon: 'TA10',
  event_slug: 'fittings_and_contents',
  trackingEvents: {
    startedEvent: TA10_STARTED,
    completedEvent: TA10_COMPLETED,
  },
  visible: true,
  active: false,
  status: false,
  whatsNextInfo: false,
};
